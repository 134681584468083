import { LinkTypes } from 'fitify-ui-landing/src/@types'
import Button from 'fitify-ui-landing/src/components/Button/Button'
import { useTranslation } from 'next-i18next'

const NavigationButton = ({ item }: { item: LinkTypes.Navigation }) => {
  const { t } = useTranslation()
  return (
    <Button
      href={item.link}
      icon={item.icon}
      target={item.link_type || '_blank'}
    >
      {t(item.caption)}
    </Button>
  )
}

export default NavigationButton
