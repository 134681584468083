import { StyledIcon } from '../Icon.Styled'

export const CalendarDate = (props: any) => {
  return (
    <StyledIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 1.5V3H0V23H24V3H18V0H16V3H8V0H6V1.5ZM22 6V7H2V5H22V6ZM22 15V21H2V9H22V15ZM4 12.5V14H8V11H4V12.5ZM10 12.5V14H14V11H10V12.5ZM16 12.5V14H20V11H16V12.5ZM4 17.5V19H8V16H4V17.5ZM10 17.5V19H14V16H10V17.5Z"
        fill="currentColor"
      />
    </StyledIcon>
  )
}
