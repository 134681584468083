import { StyledIcon } from '../Icon.Styled'

export const MenuCollapsedIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.688 2.437a.813.813 0 0 1 1.625 0v3.25h3.25a.813.813 0 0 1 0 1.626h-3.25v3.25a.812.812 0 1 1-1.625 0v-3.25h-3.25a.812.812 0 1 1 0-1.625h3.25v-3.25Z"
      fill="currentColor"
    />
    <path
      d="M2.66667 0H13.3333C14.8 0 16 1.2 16 2.66667V13.3333C16 14.8 14.8 16 13.3333 16H2.66667C1.2 16 0 14.8 0 13.3333V2.66667C0 1.2 1.2 0 2.66667 0ZM13.3333 14.6667c.7334 0 1.3334-.6 1.3334-1.3334V2.66667c0-.73334-.6-1.33334-1.3334-1.33334H2.66667c-.73334 0-1.33334.6-1.33334 1.33334V13.3333c0 .7334.6 1.3334 1.33334 1.3334H13.3333Z"
      fill="currentColor"
    />
    <path
      d="M10.6673 0c.4 0 .6667.26667.6667.66667V15.3333c0 .4-.2667.6667-.6667.6667-.4 0-.6666-.2667-.6666-.6667V.66667c0-.4.2666-.66667.6666-.66667ZM6.66732 7.33341c.2 0 .33333.06667.46666.2.26667.26667.26667.66667 0 .93334L5.13399 10.4667c-.26667.2667-.66667.2667-.93334 0-.26667-.2666-.26667-.66662 0-.93329l2-2c.13333-.13333.26667-.2.46667-.2Z"
      fill="currentColor"
    />
    <path
      d="M4.66732 5.33341c.2 0 .33333.06667.46667.2l1.99999 2c.26667.26667.26667.66667 0 .93334-.26666.26666-.66666.26666-.93333 0l-2-2c-.26667-.26667-.26667-.66667 0-.93334.13333-.13333.26667-.2.46667-.2Z"
      fill="currentColor"
    />
  </StyledIcon>
)
