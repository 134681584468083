import { StyledIcon } from '../Icon.Styled'

export const AppstoreBadgeIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="162"
    height="48"
    viewBox="0 0 162 48"
    fill="#fff"
    {...props}
  >
    <g clipPath="url(#clip0_2228_4473)">
      <rect
        x="0.5"
        y="0.5"
        width="161"
        height="47"
        rx="7.5"
        fill="white"
        stroke="black"
      />
      <path
        d="M29.7177 24.3608C29.7306 23.3592 29.9967 22.3771 30.4911 21.506C30.9855 20.6348 31.6923 19.9029 32.5456 19.3783C32.0035 18.6041 31.2884 17.967 30.457 17.5175C29.6256 17.0681 28.7008 16.8187 27.7562 16.7891C25.7411 16.5776 23.7876 17.9949 22.7607 17.9949C21.714 17.9949 20.133 16.8101 18.4305 16.8451C17.3293 16.8807 16.2561 17.2009 15.3154 17.7746C14.3748 18.3483 13.5988 19.1558 13.063 20.1186C10.7422 24.1367 12.4733 30.0419 14.6964 33.2899C15.8087 34.8803 17.1087 36.6568 18.8096 36.5938C20.4741 36.5248 21.0957 35.5324 23.1049 35.5324C25.0954 35.5324 25.6786 36.5938 27.4141 36.5538C29.2001 36.5248 30.3254 34.9563 31.3987 33.3508C32.1979 32.2176 32.8128 30.9651 33.2208 29.6398C32.1832 29.2009 31.2976 28.4663 30.6747 27.5275C30.0518 26.5888 29.7189 25.4874 29.7177 24.3608Z"
        fill="black"
      />
      <path
        d="M26.4427 14.6536C27.4166 13.4845 27.8963 11.9819 27.7802 10.4648C26.2924 10.6211 24.9181 11.3322 23.9311 12.4564C23.4485 13.0056 23.0789 13.6445 22.8434 14.3366C22.6079 15.0287 22.5111 15.7605 22.5586 16.49C23.3028 16.4977 24.039 16.3364 24.7118 16.0183C25.3846 15.7002 25.9764 15.2336 26.4427 14.6536Z"
        fill="black"
      />
      <path
        d="M50.7703 32.5672H45.0902L43.7262 36.595H41.3203L46.7004 21.6934H49.2L54.5801 36.595H52.1332L50.7703 32.5672ZM45.6785 30.7086H50.1809L47.9614 24.1719H47.8993L45.6785 30.7086Z"
        fill="black"
      />
      <path
        d="M66.195 31.1637C66.195 34.5399 64.3879 36.709 61.661 36.709C60.9702 36.7452 60.2832 36.5861 59.6786 36.2499C59.0741 35.9137 58.5764 35.4141 58.2426 34.8082H58.191V40.1895H55.9609V25.7309H58.1196V27.5379H58.1606C58.5097 26.935 59.0158 26.438 59.625 26.0997C60.2341 25.7615 60.9236 25.5948 61.62 25.6172C64.3773 25.6172 66.195 27.7969 66.195 31.1637ZM63.9028 31.1637C63.9028 28.9641 62.766 27.518 61.0317 27.518C59.3278 27.518 58.1817 28.9946 58.1817 31.1637C58.1817 33.3528 59.3278 34.8188 61.0317 34.8188C62.7661 34.8188 63.9028 33.3833 63.9028 31.1637Z"
        fill="black"
      />
      <path
        d="M78.1637 31.1637C78.1637 34.5399 76.3567 36.709 73.6297 36.709C72.9389 36.7452 72.2519 36.586 71.6474 36.2499C71.0428 35.9137 70.5452 35.4141 70.2114 34.8082H70.1598V40.1895H67.9297V25.7309H70.0883V27.5379H70.1293C70.4784 26.935 70.9845 26.4379 71.5937 26.0997C72.2028 25.7615 72.8923 25.5948 73.5887 25.6172C76.3461 25.6172 78.1637 27.7969 78.1637 31.1637ZM75.8715 31.1637C75.8715 28.9641 74.7348 27.518 73.0004 27.518C71.2965 27.518 70.1504 28.9946 70.1504 31.1637C70.1504 33.3528 71.2965 34.8188 73.0004 34.8188C74.7348 34.8188 75.8715 33.3833 75.8715 31.1637Z"
        fill="black"
      />
      <path
        d="M86.0641 32.4434C86.2293 33.9212 87.6649 34.8914 89.6266 34.8914C91.5063 34.8914 92.8586 33.9211 92.8586 32.5887C92.8586 31.432 92.043 30.7395 90.1118 30.2649L88.1805 29.7996C85.4442 29.1387 84.1739 27.859 84.1739 25.7824C84.1739 23.2113 86.4145 21.4453 89.5961 21.4453C92.7449 21.4453 94.9035 23.2113 94.9762 25.7824H92.725C92.5902 24.2953 91.3609 23.3977 89.5645 23.3977C87.768 23.3977 86.5387 24.3059 86.5387 25.6277C86.5387 26.6813 87.3238 27.3012 89.2445 27.7757L90.8863 28.1789C93.9437 28.9019 95.2141 30.1301 95.2141 32.3097C95.2141 35.0976 92.9934 36.8437 89.4613 36.8437C86.1566 36.8437 83.9254 35.1386 83.7812 32.4433L86.0641 32.4434Z"
        fill="black"
      />
      <path
        d="M100.023 23.1602V25.7313H102.089V27.4973H100.023V33.4867C100.023 34.4172 100.437 34.8508 101.345 34.8508C101.591 34.8465 101.835 34.8293 102.079 34.7992V36.5547C101.671 36.6309 101.256 36.6655 100.84 36.6578C98.6406 36.6578 97.7828 35.8316 97.7828 33.7246V27.4973H96.2031V25.7313H97.7828V23.1602H100.023Z"
        fill="black"
      />
      <path
        d="M103.289 31.1641C103.289 27.7457 105.302 25.5977 108.442 25.5977C111.592 25.5977 113.596 27.7457 113.596 31.1641C113.596 34.5918 111.602 36.7305 108.442 36.7305C105.282 36.7305 103.289 34.5918 103.289 31.1641ZM111.323 31.1641C111.323 28.8191 110.249 27.4352 108.442 27.4352C106.635 27.4352 105.561 28.8297 105.561 31.1641C105.561 33.5184 106.635 34.8918 108.442 34.8918C110.249 34.8918 111.323 33.5184 111.323 31.1641Z"
        fill="black"
      />
      <path
        d="M115.43 25.7308H117.557V27.58H117.608C117.752 27.0025 118.09 26.4921 118.566 26.1346C119.042 25.7771 119.627 25.5944 120.222 25.6171C120.479 25.6162 120.735 25.6441 120.986 25.7003V27.7863C120.661 27.6871 120.323 27.6416 119.984 27.6515C119.66 27.6384 119.337 27.6955 119.037 27.8189C118.737 27.9424 118.467 28.1293 118.247 28.3668C118.026 28.6042 117.859 28.8867 117.758 29.1947C117.657 29.5027 117.623 29.829 117.66 30.1511V36.5953H115.43L115.43 25.7308Z"
        fill="black"
      />
      <path
        d="M131.269 33.4047C130.969 35.3769 129.048 36.7305 126.591 36.7305C123.43 36.7305 121.469 34.6129 121.469 31.2156C121.469 27.8078 123.441 25.5977 126.497 25.5977C129.503 25.5977 131.393 27.6625 131.393 30.9566V31.7207H123.72V31.8555C123.685 32.2553 123.735 32.6581 123.867 33.0371C123.999 33.4161 124.21 33.7627 124.487 34.0537C124.763 34.3448 125.098 34.5737 125.47 34.7253C125.842 34.8768 126.241 34.9476 126.643 34.9328C127.17 34.9822 127.698 34.8601 128.15 34.5848C128.603 34.3095 128.954 33.8956 129.152 33.4047L131.269 33.4047ZM123.73 30.1621H129.162C129.182 29.8026 129.128 29.4428 129.002 29.1054C128.876 28.7679 128.682 28.4601 128.432 28.2012C128.182 27.9422 127.881 27.7378 127.548 27.6008C127.215 27.4637 126.857 27.397 126.497 27.4047C126.134 27.4025 125.774 27.4723 125.438 27.61C125.102 27.7477 124.797 27.9506 124.539 28.2069C124.282 28.4633 124.078 28.768 123.939 29.1036C123.801 29.4392 123.73 29.7989 123.73 30.1621Z"
        fill="black"
      />
      <path
        d="M45.391 10.4769C45.8585 10.4433 46.3277 10.514 46.7646 10.6837C47.2016 10.8534 47.5954 11.118 47.9177 11.4583C48.24 11.7987 48.4827 12.2063 48.6284 12.6518C48.7741 13.0974 48.8191 13.5697 48.7602 14.0347C48.7602 16.3222 47.5238 17.6371 45.391 17.6371H42.8047V10.4769H45.391ZM43.9168 16.6245H45.2668C45.6009 16.6444 45.9352 16.5897 46.2455 16.4641C46.5557 16.3386 46.8341 16.1455 47.0602 15.8988C47.2864 15.6521 47.4548 15.3581 47.553 15.0381C47.6512 14.7182 47.6769 14.3804 47.6281 14.0493C47.6734 13.7195 47.6451 13.3837 47.5454 13.0662C47.4456 12.7486 47.2769 12.457 47.0512 12.2122C46.8255 11.9675 46.5485 11.7757 46.24 11.6507C45.9315 11.5256 45.5992 11.4703 45.2668 11.4887H43.9168V16.6245Z"
        fill="black"
      />
      <path
        d="M50.0199 14.9345C49.9859 14.5794 50.0265 14.2211 50.1391 13.8826C50.2517 13.5442 50.4338 13.233 50.6738 12.969C50.9137 12.705 51.2062 12.4941 51.5324 12.3497C51.8586 12.2054 52.2114 12.1309 52.5681 12.1309C52.9248 12.1309 53.2776 12.2054 53.6038 12.3497C53.9301 12.4941 54.2225 12.705 54.4624 12.969C54.7024 13.233 54.8845 13.5442 54.9971 13.8826C55.1097 14.2211 55.1503 14.5794 55.1164 14.9345C55.151 15.29 55.1108 15.6488 54.9985 15.9878C54.8862 16.3268 54.7042 16.6386 54.4642 16.9031C54.2242 17.1676 53.9316 17.379 53.605 17.5236C53.2785 17.6682 52.9253 17.743 52.5681 17.743C52.211 17.743 51.8578 17.6682 51.5312 17.5236C51.2047 17.379 50.912 17.1676 50.672 16.9031C50.432 16.6386 50.25 16.3268 50.1377 15.9878C50.0254 15.6488 49.9853 15.29 50.0199 14.9345ZM54.0195 14.9345C54.0195 13.7632 53.4933 13.0783 52.5699 13.0783C51.6429 13.0783 51.1215 13.7632 51.1215 14.9345C51.1215 16.1152 51.643 16.7949 52.5699 16.7949C53.4933 16.7949 54.0195 16.1105 54.0195 14.9345Z"
        fill="black"
      />
      <path
        d="M61.8875 17.6379H60.7813L59.6645 13.6582H59.5801L58.468 17.6379H57.3723L55.8828 12.2344H56.9645L57.9324 16.3576H58.0121L59.1231 12.2344H60.1461L61.257 16.3576H61.3414L62.3047 12.2344H63.3711L61.8875 17.6379Z"
        fill="black"
      />
      <path
        d="M64.6172 12.2346H65.6438V13.093H65.7234C65.8586 12.7847 66.0866 12.5263 66.3757 12.3537C66.6647 12.1811 67.0004 12.103 67.3359 12.1303C67.5989 12.1106 67.8629 12.1502 68.1084 12.2463C68.3539 12.3424 68.5746 12.4925 68.7543 12.6856C68.9339 12.8786 69.0678 13.1095 69.146 13.3613C69.2243 13.6131 69.2448 13.8793 69.2063 14.1401V17.6381H68.1398V14.4079C68.1398 13.5395 67.7625 13.1077 66.9738 13.1077C66.7953 13.0994 66.6171 13.1297 66.4515 13.1967C66.2858 13.2637 66.1366 13.3658 66.014 13.4958C65.8915 13.6259 65.7985 13.7809 65.7415 13.9503C65.6844 14.1196 65.6647 14.2993 65.6836 14.477V17.6381H64.6172L64.6172 12.2346Z"
        fill="black"
      />
      <path d="M70.9141 10.125H71.9805V17.6379H70.9141V10.125Z" fill="black" />
      <path
        d="M73.4651 14.9327C73.4312 14.5775 73.4719 14.2192 73.5845 13.8807C73.6971 13.5422 73.8793 13.231 74.1192 12.967C74.3592 12.7031 74.6517 12.4921 74.9779 12.3478C75.3041 12.2035 75.6569 12.1289 76.0137 12.1289C76.3704 12.1289 76.7232 12.2035 77.0495 12.3478C77.3757 12.4921 77.6682 12.7031 77.9082 12.967C78.1481 13.231 78.3302 13.5422 78.4429 13.8807C78.5555 14.2192 78.5962 14.5775 78.5622 14.9327C78.5968 15.2881 78.5566 15.6469 78.4443 15.986C78.3319 16.325 78.1499 16.6368 77.9099 16.9012C77.6699 17.1657 77.3772 17.3771 77.0506 17.5217C76.724 17.6664 76.3708 17.7411 76.0137 17.7411C75.6565 17.7411 75.3033 17.6664 74.9768 17.5217C74.6502 17.3771 74.3575 17.1657 74.1175 16.9012C73.8775 16.6368 73.6954 16.325 73.5831 15.986C73.4708 15.6469 73.4306 15.2881 73.4651 14.9327ZM77.4647 14.9327C77.4647 13.7614 76.9386 13.0764 76.0151 13.0764C75.0882 13.0764 74.5668 13.7614 74.5668 14.9327C74.5668 16.1133 75.0882 16.793 76.0151 16.793C76.9386 16.793 77.4647 16.1086 77.4647 14.9327Z"
        fill="black"
      />
      <path
        d="M79.6797 16.1105C79.6797 15.1379 80.4039 14.5771 81.6894 14.4975L83.1531 14.4131V13.9467C83.1531 13.376 82.7758 13.0537 82.0469 13.0537C81.4516 13.0537 81.0391 13.2723 80.9207 13.6543H79.8883C79.9973 12.7262 80.8703 12.1309 82.0961 12.1309C83.4508 12.1309 84.2148 12.8053 84.2148 13.9467V17.6387H83.1883V16.8793H83.1039C82.9326 17.1517 82.6922 17.3737 82.407 17.5228C82.1219 17.6719 81.8023 17.7426 81.4809 17.7277C81.254 17.7513 81.0248 17.7271 80.8078 17.6567C80.5909 17.5862 80.3912 17.4711 80.2215 17.3187C80.0518 17.1664 79.9159 16.9801 79.8226 16.772C79.7293 16.5639 79.6806 16.3386 79.6797 16.1105ZM83.1531 15.6488V15.1971L81.8336 15.2814C81.0894 15.3312 80.7519 15.5844 80.7519 16.0607C80.7519 16.5471 81.1738 16.8301 81.7539 16.8301C81.9239 16.8473 82.0956 16.8301 82.2588 16.7796C82.422 16.7291 82.5734 16.6463 82.7039 16.5361C82.8345 16.4258 82.9415 16.2905 83.0187 16.1381C83.0959 15.9857 83.1416 15.8193 83.1531 15.6488Z"
        fill="black"
      />
      <path
        d="M85.6172 14.9338C85.6172 13.2264 86.4949 12.1447 87.8602 12.1447C88.1979 12.1292 88.533 12.2101 88.8264 12.3779C89.1199 12.5458 89.3595 12.7937 89.5172 13.0927H89.5969V10.125H90.6633V17.6379H89.6414V16.7842H89.557C89.3871 17.0812 89.1392 17.326 88.8401 17.4922C88.541 17.6584 88.2021 17.7395 87.8602 17.7269C86.4856 17.727 85.6172 16.6453 85.6172 14.9338ZM86.7188 14.9338C86.7188 16.0799 87.259 16.7696 88.1625 16.7696C89.0613 16.7696 89.6168 16.07 89.6168 14.9385C89.6168 13.8123 89.0555 13.1028 88.1625 13.1028C87.2648 13.1028 86.7188 13.7971 86.7188 14.9338Z"
        fill="black"
      />
      <path
        d="M95.0746 14.9345C95.0406 14.5794 95.0812 14.2211 95.1938 13.8826C95.3064 13.5442 95.4885 13.233 95.7285 12.969C95.9684 12.705 96.2608 12.4941 96.5871 12.3497C96.9133 12.2054 97.2661 12.1309 97.6228 12.1309C97.9795 12.1309 98.3323 12.2054 98.6585 12.3497C98.9847 12.4941 99.2772 12.705 99.5171 12.969C99.7571 13.233 99.9392 13.5442 100.052 13.8826C100.164 14.2211 100.205 14.5794 100.171 14.9345C100.206 15.29 100.166 15.6488 100.053 15.9878C99.9409 16.3268 99.7589 16.6386 99.5189 16.9031C99.2789 17.1676 98.9862 17.379 98.6597 17.5236C98.3331 17.6682 97.9799 17.743 97.6228 17.743C97.2656 17.743 96.9124 17.6682 96.5859 17.5236C96.2594 17.379 95.9667 17.1676 95.7267 16.9031C95.4867 16.6386 95.3047 16.3268 95.1924 15.9878C95.0801 15.6488 95.0399 15.29 95.0746 14.9345ZM99.0742 14.9345C99.0742 13.7632 98.548 13.0783 97.6246 13.0783C96.6976 13.0783 96.1762 13.7632 96.1762 14.9345C96.1762 16.1152 96.6976 16.7949 97.6246 16.7949C98.548 16.7949 99.0742 16.1105 99.0742 14.9345Z"
        fill="black"
      />
      <path
        d="M101.602 12.2346H102.628V13.093H102.708C102.843 12.7847 103.071 12.5263 103.36 12.3537C103.649 12.1811 103.985 12.103 104.32 12.1303C104.583 12.1106 104.847 12.1502 105.093 12.2463C105.338 12.3424 105.559 12.4925 105.739 12.6856C105.918 12.8786 106.052 13.1095 106.13 13.3613C106.209 13.6131 106.229 13.8793 106.191 14.1401V17.6381H105.124V14.4079C105.124 13.5395 104.747 13.1077 103.958 13.1077C103.78 13.0994 103.601 13.1297 103.436 13.1967C103.27 13.2637 103.121 13.3658 102.998 13.4958C102.876 13.6259 102.783 13.7809 102.726 13.9503C102.669 14.1196 102.649 14.2993 102.668 14.477V17.6381H101.602V12.2346Z"
        fill="black"
      />
      <path
        d="M112.222 10.8887V12.2586H113.393V13.1568H112.222V15.9354C112.222 16.5014 112.455 16.7492 112.986 16.7492C113.122 16.7488 113.258 16.7406 113.393 16.7246V17.6129C113.201 17.6471 113.007 17.6654 112.813 17.6674C111.627 17.6674 111.155 17.2502 111.155 16.2084V13.1568H110.297V12.2585H111.155V10.8887H112.222Z"
        fill="black"
      />
      <path
        d="M114.844 10.125H115.901V13.1027H115.985C116.127 12.7916 116.361 12.5316 116.656 12.3584C116.951 12.1852 117.292 12.1071 117.633 12.1348C117.894 12.1205 118.156 12.1642 118.399 12.2627C118.641 12.3611 118.859 12.5119 119.037 12.7043C119.215 12.8967 119.348 13.126 119.427 13.3757C119.506 13.6255 119.529 13.8896 119.494 14.1492V17.6379H118.426V14.4123C118.426 13.5492 118.024 13.1121 117.271 13.1121C117.087 13.0971 116.903 13.1223 116.731 13.1859C116.558 13.2496 116.402 13.3502 116.272 13.4807C116.142 13.6112 116.043 13.7684 115.98 13.9413C115.918 14.1143 115.894 14.2988 115.91 14.482V17.6379H114.844L114.844 10.125Z"
        fill="black"
      />
      <path
        d="M125.712 16.1778C125.567 16.6717 125.253 17.0988 124.825 17.3846C124.397 17.6704 123.882 17.7966 123.371 17.7411C123.015 17.7505 122.661 17.6823 122.334 17.5413C122.007 17.4002 121.715 17.1897 121.477 16.9244C121.24 16.659 121.063 16.3451 120.959 16.0046C120.855 15.664 120.826 15.3048 120.875 14.952C120.827 14.5982 120.856 14.2383 120.96 13.8967C121.064 13.5551 121.24 13.2397 121.476 12.972C121.712 12.7044 122.003 12.4905 122.329 12.3451C122.655 12.1996 123.009 12.126 123.366 12.129C124.87 12.129 125.777 13.1562 125.777 14.853V15.2251H121.961V15.2849C121.944 15.4832 121.969 15.6828 122.034 15.8709C122.099 16.059 122.203 16.2314 122.338 16.3772C122.474 16.5229 122.638 16.6387 122.821 16.7172C123.004 16.7957 123.201 16.8351 123.4 16.8329C123.655 16.8635 123.914 16.8176 124.142 16.7009C124.371 16.5843 124.56 16.4022 124.686 16.1778L125.712 16.1778ZM121.961 14.4364H124.69C124.704 14.2551 124.679 14.0729 124.618 13.9016C124.557 13.7304 124.46 13.5738 124.335 13.442C124.21 13.3101 124.058 13.206 123.891 13.1362C123.723 13.0664 123.542 13.0324 123.36 13.0366C123.176 13.0343 122.993 13.0689 122.822 13.1385C122.651 13.208 122.496 13.311 122.365 13.4415C122.235 13.5719 122.132 13.7272 122.063 13.8981C121.993 14.0689 121.959 14.252 121.961 14.4364Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_2228_4473">
        <rect width="162" height="48" fill="white" />
      </clipPath>
    </defs>
  </StyledIcon>
)
