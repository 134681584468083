import { StyledIcon } from '../Icon.Styled'

export const InterviewIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
    {...props}
    fill="none"
  >
    <g clipPath="url(#clip0_12202_87809)">
      <path
        d="M24.0003 1.70347e-06C21.3987 -0.00113169 18.828 0.563317 16.4662 1.65425C14.1044 2.74518 12.008 4.33651 10.3223 6.318L13.1543 9.15C14.4672 7.54135 16.1216 6.2449 17.9975 5.35458C19.8734 4.46425 21.9238 4.00236 24.0003 4.00236C26.0767 4.00236 28.1272 4.46425 30.0031 5.35458C31.8789 6.2449 33.5333 7.54135 34.8463 9.15L37.6783 6.318C35.9925 4.33651 33.8961 2.74518 31.5343 1.65425C29.1725 0.563317 26.6018 -0.00113169 24.0003 1.70347e-06Z"
        fill="#95EB00"
      />
      <path
        d="M24.0004 8C22.4494 8.00325 20.9206 8.36875 19.5358 9.06738C18.1511 9.76601 16.9487 10.7784 16.0244 12.024L18.8924 14.892C19.4219 14.0102 20.1706 13.2805 21.0657 12.7738C21.9608 12.2672 22.9719 12.0009 24.0004 12.0009C25.029 12.0009 26.04 12.2672 26.9351 12.7738C27.8302 13.2805 28.579 14.0102 29.1084 14.892L31.9764 12.024C31.0522 10.7784 29.8497 9.76601 28.465 9.06738C27.0802 8.36875 25.5514 8.00325 24.0004 8Z"
        fill="#95EB00"
      />
      <path
        d="M8 48H0V10H2C5.71303 10 9.27399 11.475 11.8995 14.1005C14.525 16.726 16 20.287 16 24V25.666L18.834 32.2C18.9439 32.4542 18.9998 32.7284 18.9982 33.0052C18.9966 33.2821 18.9376 33.5557 18.8247 33.8085C18.7119 34.0614 18.5479 34.2881 18.3429 34.4743C18.1379 34.6604 17.8965 34.802 17.634 34.89L16 35.442V40C16 41.0609 15.5786 42.0783 14.8284 42.8284C14.0783 43.5786 13.0609 44 12 44H8V48Z"
        fill="#95EB00"
      />
      <path
        d="M40.0002 48H48.0002V10H46.0002C42.2872 10 38.7262 11.475 36.1007 14.1005C33.4752 16.726 32.0002 20.287 32.0002 24V25.666L29.1662 32.2C29.0563 32.4542 29.0004 32.7284 29.002 33.0052C29.0036 33.2821 29.0626 33.5557 29.1755 33.8085C29.2883 34.0614 29.4523 34.2881 29.6573 34.4743C29.8623 34.6604 30.1037 34.802 30.3662 34.89L31.9982 35.436V40C31.9982 41.0609 32.4196 42.0783 33.1698 42.8284C33.9199 43.5786 34.9373 44 35.9982 44H39.9982L40.0002 48Z"
        fill="#95EB00"
      />
    </g>
    <defs>
      <clipPath id="clip0_12202_87809">
        <rect width="48" height="48" fill="white" />
      </clipPath>
    </defs>
  </StyledIcon>
)
