import { StyledIcon } from '../Icon.Styled'

export const CrossSmallIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="8"
    viewBox="0 0 8 8"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.47 1.53A.75.75 0 0 1 1.53.47l2.475 2.475L6.48.47a.75.75 0 1 1 1.06 1.06L5.067 4.005 7.541 6.48a.75.75 0 0 1-1.06 1.06L4.004 5.067 1.53 7.541A.75.75 0 0 1 .47 6.48l2.475-2.476L.47 1.53Z"
      fill="currentColor"
    />
  </StyledIcon>
)
