import { StyledIcon } from '../Icon.Styled'

export const TrxToolIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.703 1.957a.903.903 0 0 1 1.805 0V6.06c0 .02 0 .041-.002.062.152.096.295.219.424.368l5.087 6.61c.684.789.73 2.035.138 2.886a.82.82 0 0 1-.065.147c-.89 1.57-2.932 4.195-6.653 4.199-3.759.003-5.616-2.679-6.38-4.244a.8.8 0 0 1-.064-.188 2.389 2.389 0 0 1-.355-1.268c0-.575.198-1.126.55-1.532l5.087-6.61c.13-.151.276-.275.43-.372a.857.857 0 0 1-.002-.058V1.957Zm6.159 11.574H10.315L15.16 7.67a.575.575 0 0 1 .857 0l4.845 5.86Zm-10.853 2.45h11.074a.361.361 0 0 1-.045.135c-.6 1.061-2.27 3.358-5.522 3.393-3.272.036-4.897-2.326-5.467-3.4a.363.363 0 0 1-.04-.128Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.454 3.718a.937.937 0 0 1 1.874 0v4.258c0 .022 0 .044-.002.065.158.1.306.227.44.382l5.282 6.863c.704.812.757 2.09.16 2.973a.835.835 0 0 1-.06.134c-.91 1.624-3.035 4.398-6.932 4.402-3.935.004-5.862-2.827-6.643-4.447a.807.807 0 0 1-.062-.177 2.483 2.483 0 0 1-.354-1.294c0-.597.205-1.17.57-1.591L8.01 8.423a2 2 0 0 1 .447-.386.952.952 0 0 1-.002-.06v-4.26Zm6.394 12.017H3.898l5.03-6.086a.597.597 0 0 1 .89 0l5.03 6.086ZM3.574 18.279h11.51a.369.369 0 0 1-.042.12c-.612 1.093-2.347 3.506-5.744 3.543-3.416.037-5.104-2.443-5.686-3.55a.368.368 0 0 1-.038-.113Z"
      clipRule="evenodd"
    />
  </StyledIcon>
)
