import { StyledIcon } from '../Icon.Styled'

export const AddInstanceIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    {...props}
  >
    <circle cx="8" cy="8" r="6" fill="#E0E0E0" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.38822 5.62404C7.38822 5.38081 7.58539 5.18364 7.82861 5.18364C8.07184 5.18364 8.26901 5.38081 8.26901 5.62404L8.26901 7.39269L10.0304 7.39269C10.2737 7.39269 10.4708 7.58986 10.4708 7.83309C10.4708 8.07631 10.2737 8.27348 10.0304 8.27348L8.26901 8.27348V10.028C8.26901 10.2712 8.07184 10.4684 7.82861 10.4684C7.58539 10.4684 7.38822 10.2712 7.38822 10.028L7.38822 8.27348L5.62647 8.27348C5.38324 8.27348 5.18607 8.07631 5.18607 7.83309C5.18607 7.58986 5.38324 7.39269 5.62647 7.39269L7.38822 7.39269L7.38822 5.62404Z"
      fill="#BDBDBD"
    />
  </StyledIcon>
)
