import { StyledIcon } from '../Icon.Styled'

export const SadFaceIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip1)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11.26.526c-1.217.097-2.197.294-3.198.642a12.012 12.012 0 0 0-7.857 9.156c-.145.782-.181 1.22-.181 2.176 0 .955.036 1.394.18 2.176a12.01 12.01 0 0 0 8.755 9.428 12.001 12.001 0 0 0 9.76-1.675 11.978 11.978 0 0 0 5.104-11.93 11.98 11.98 0 0 0-7.95-9.352C14.463.662 12.638.416 11.26.526ZM13.44 2.6c1.076.168 2.028.467 2.98.938a9.953 9.953 0 0 1 3.928 3.456c.598.897 1.182 2.263 1.408 3.286.179.816.222 1.245.222 2.22 0 .975-.043 1.404-.222 2.22-.298 1.35-1.071 2.949-1.958 4.046a14.37 14.37 0 0 1-1.532 1.532c-1.097.887-2.695 1.66-4.046 1.958-.816.179-1.245.222-2.22.222-1.29 0-2.013-.113-3.18-.5-2.084-.691-3.92-2.101-5.167-3.97-.594-.887-1.183-2.264-1.41-3.288-.178-.816-.221-1.245-.221-2.22 0-1.29.113-2.013.5-3.18.587-1.77 1.729-3.412 3.21-4.616 1.37-1.112 3.275-1.921 4.996-2.121l.492-.06c.278-.038 1.822.015 2.22.077ZM7.117 9.552c-.417.119-.798.45-.99.86-.09.193-.106.283-.106.589-.001.327.01.386.129.624.156.318.442.599.76.747.195.09.285.107.59.107.328 0 .387-.011.625-.13.318-.155.599-.441.747-.76.09-.195.107-.283.107-.59 0-.305-.016-.393-.107-.588a1.524 1.524 0 0 0-1.276-.894c-.176-.01-.37.004-.48.035Zm9 0c-.417.119-.798.45-.99.86-.09.193-.106.283-.106.589-.001.327.01.386.129.624.156.318.442.599.76.747.195.09.285.107.59.107.328 0 .387-.011.625-.13.318-.155.599-.441.747-.76.09-.195.107-.283.107-.59 0-.305-.016-.393-.107-.588a1.523 1.523 0 0 0-1.276-.894c-.176-.01-.37.004-.48.035Zm-4.74 5.006c-1.45.225-2.68 1.264-3.162 2.674-.094.274-.214.923-.215 1.158v.11h2v-.132c0-.411.233-.91.596-1.272.403-.403.858-.596 1.404-.596.546 0 1 .193 1.404.596.363.362.596.86.596 1.272v.132h2v-.11c-.001-.235-.121-.884-.215-1.158a4.004 4.004 0 0 0-4.407-2.674Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip1">
        <path fill="#fff" d="M0 .5h24v24H0z" />
      </clipPath>
    </defs>
  </StyledIcon>
)
