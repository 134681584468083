import { StyledIcon } from '../Icon.Styled'

export const ButtonPlay = (props: any) => {
  return (
    <StyledIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.555 11.168L5.555 1.16796C5.248 0.963964 4.853 0.943964 4.529 1.11796C4.203 1.29196 4 1.63096 4 1.99996V22C4 22.369 4.203 22.708 4.528 22.882C4.676 22.961 4.838 23 5 23C5.194 23 5.388 22.943 5.555 22.832L20.555 12.832C20.833 12.646 21 12.334 21 12C21 11.666 20.833 11.354 20.555 11.168Z"
        fill="currentColor"
      />
    </StyledIcon>
  )
}
