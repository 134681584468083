import { StyledIcon } from '../Icon.Styled'

export const ButtonStop = (props: any) => {
  return (
    <StyledIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22 1H2C1.447 1 1 1.447 1 2V22C1 22.553 1.447 23 2 23H22C22.553 23 23 22.553 23 22V2C23 1.447 22.553 1 22 1Z"
        fill="currentColor"
      />
    </StyledIcon>
  )
}
