import { StyledIcon } from '../Icon.Styled'

export const CrossIcon = (props: any) => {
  return (
    <StyledIcon
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        d="M13.133 2.867a.644.644 0 0 0-.933 0L8 7.067l-4.2-4.2a.644.644 0 0 0-.933 0 .644.644 0 0 0 0 .933l4.2 4.2-4.2 4.2a.644.644 0 0 0 0 .933c.133.134.266.2.466.2s.334-.066.467-.2l4.2-4.2 4.2 4.2a.72.72 0 0 0 .467.2.72.72 0 0 0 .466-.2.644.644 0 0 0 0-.933L8.933 8l4.2-4.2a.644.644 0 0 0 0-.933Z"
      />
    </StyledIcon>
  )
}
