import { StyledIcon } from '../Icon.Styled'

export const ResistanceBandToolIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 41 40"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M27.298 26.506c-3.226 0-6.237-1.28-8.49-3.611a12.52 12.52 0 0 1-3.537-8.738c0-3.274 1.256-6.377 3.538-8.739l.793.822a11.343 11.343 0 0 0-3.209 7.917c0 2.963 1.14 5.774 3.21 7.916a10.608 10.608 0 0 0 7.696 3.271h.096c2.954-.026 5.724-1.224 7.801-3.373 4.644-4.808 4.616-10.346-.081-16.017-2.482-2.996-5.565-4.65-8.915-4.783-3.8-.15-7.626 1.649-11.06 5.204-2.06 2.131-3.745 5.184-4.511 8.167l-1.085-.298c.814-3.173 2.61-6.421 4.802-8.69C18.007 1.763 22.12-.155 26.243.01c3.67.145 7.034 1.939 9.724 5.187 2.488 3.004 3.774 6.022 3.823 8.973.05 3.059-1.228 5.96-3.8 8.622-2.286 2.367-5.335 3.686-8.586 3.714h-.106ZM20.324 39.319c.51.43 1.155.68 1.841.68.333 0 .661-.059.977-.175 1.615-.595 2.909-1.805 3.644-3.408a6.81 6.81 0 0 0 .249-5.077c-.909-2.64-3.338-4.415-6.045-4.415a6.18 6.18 0 0 0-1.617.216l-.012-.034-.527.194c-.993.366-1.675 1.237-1.875 2.234.04-.017.08-.034.122-.05l.009-.002a2.319 2.319 0 0 1 1.1-.125c.17-.376.461-.694.842-.884l.183-.068a5.121 5.121 0 0 1 1.775-.319c2.234 0 4.238 1.465 4.987 3.644a5.619 5.619 0 0 1-.205 4.188c-.606 1.322-1.674 2.32-3.006 2.812a1.73 1.73 0 0 1-1.38-.078 2.378 2.378 0 0 1-1.062.667Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17.893 30.5c.523 0 1.014.337 1.2.877l1.76 5.12c.237.685-.11 1.439-.772 1.683a1.232 1.232 0 0 1-.427.076 1.276 1.276 0 0 1-1.198-.876l-1.762-5.12c-.236-.686.11-1.44.772-1.683.141-.052.286-.077.427-.077Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.57 38.552c-.722 0-1.37-.465-1.612-1.157l-1.643-4.692c-.316-.904.15-1.901 1.038-2.223.185-.067.379-.101.574-.101.722 0 1.37.465 1.612 1.157l1.643 4.692c.153.438.13.91-.066 1.33a1.71 1.71 0 0 1-1.546.994ZM12.646 23.791a2.874 2.874 0 0 0 1.641-1.116c.199-.275.35-.583.449-.915a6.884 6.884 0 0 0-.446-5.08c-.794-1.58-2.128-2.745-3.758-3.281-2.575-.847-5.392.09-7.01 2.33a6.672 6.672 0 0 0-.8 1.47l-.032-.01-.165.553a3.132 3.132 0 0 0 .59 2.895c.009-.039.02-.077.03-.115a2.507 2.507 0 0 1 .577-1.002 1.928 1.928 0 0 1-.177-1.23l.057-.193c.18-.603.455-1.163.815-1.663 1.334-1.848 3.658-2.62 5.783-1.922 1.344.442 2.445 1.403 3.1 2.706a5.678 5.678 0 0 1 .367 4.191c-.06.204-.153.394-.275.563-.17.234-.385.417-.628.542a2.575 2.575 0 0 1-.118 1.277Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.41 20.474a1.234 1.234 0 0 1 1.389-.462l4.987 1.64c.668.22 1.04.961.833 1.657a1.332 1.332 0 0 1-.196.4 1.233 1.233 0 0 1-1.39.461l-4.986-1.64c-.669-.22-1.041-.961-.834-1.656.045-.148.112-.283.196-.4Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.6 23.957c-.43.597-1.175.852-1.851.634l-4.59-1.48c-.883-.285-1.372-1.273-1.089-2.203.06-.194.149-.375.265-.536.432-.598 1.176-.853 1.853-.635l4.588 1.48c.428.138.778.443.984.86.206.416.243.893.106 1.344-.06.194-.149.374-.265.536ZM35.79 27.49l-.793-.822C38.028 23.53 39.6 20 39.543 16.46c-.057-3.527-1.698-6.875-4.877-9.952-4.62-4.405-10.957-4.519-15.064-.268l-.793-.821C23.357.71 30.348.81 35.43 5.656c3.365 3.257 5.174 6.985 5.235 10.785.063 3.863-1.623 7.683-4.874 11.049Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m27.201 32.528-.28-1.125c2.95-.784 5.969-2.554 8.076-4.735l.794.822c-2.243 2.321-5.454 4.205-8.59 5.038Z"
      clipRule="evenodd"
    />
  </StyledIcon>
)
