import { TextStyles, colors, StyledIcon } from 'fitify-ui'
import { phone } from 'fitify-ui-landing/src/theme/breakpoints'
import styled, { css } from 'styled-components'

import { ButtonVariants, StyledButtonProps } from './Button.Types'

const resolveVariantStyle = (variant: ButtonVariants = 'primary-blue') =>
  ({
    'primary-blue': css`
      background: ${colors.Blue400};
      color: ${colors.White};

      &:hover,
      &:focus-visible {
        background: ${colors.Blue700};
      }

      &:active {
        background: ${colors.Blue100};
      }

      &[disabled] {
        background: ${colors.Gray200};
      }
    `,
    'primary-green': css`
      background: ${colors.Green400};
      color: ${colors.Black};

      &:hover,
      &:focus-visible {
        background: ${colors.Green300};
      }

      &:active {
        background: ${colors.Green200};
      }

      &[disabled] {
        background: ${colors.Gray200};
      }
    `,
    'primary-black': css`
      background: ${colors.Black};

      &:hover,
      &:focus-visible {
        background: ${colors.Gray900};
      }

      &:active {
        background: ${colors.Gray800};
      }

      &[disabled] {
        background: ${colors.Gray200};
      }

      span {
        margin: 0 auto;
        color: ${colors.Green300};
        text-align: center;
      }
    `,
    'primary-trainer': css`
      min-width: 478px !important;
      background: ${colors.Green400};
      height: 56px;
      &:hover,
      &:focus-visible {
        background: ${colors.Green700};
      }

      &:active {
        background: ${colors.Green100};
      }

      &[disabled] {
        background: ${colors.Gray200};
      }

      ${phone(css`
        min-width: 340px !important;
      `)};
    `,
    secondary: css`
      background: ${colors.Gray200};

      &:hover,
      &:focus-visible {
        background: ${colors.Gray300};
      }

      &:active {
        background: ${colors.Gray50};
        transform: scale(0.95);
      }
    `,
    login: css`
      background: ${colors.Blue200};

      span {
        margin: 0 auto;
        color: ${colors.White};
        text-align: center;
      }

      &:hover,
      &:focus-visible {
        background: ${colors.Blue400};
      }

      &:active {
        background: ${colors.Blue400};
      }
    `,

    white: css`
      background: ${colors.White};

      &:hover,
      &:focus-visible {
        background: ${colors.Gray400};
      }

      &:active {
        background: ${colors.Gray600};
      }
    `,

    'secondary-trainer': css`
      background: ${colors.Gray200};
      height: 32px;
      width: 100%;
      text-align: center;

      span {
        margin: 0 auto;
      }

      &:hover,
      &:focus-visible {
        background: ${colors.Gray300};
      }

      &:active {
        background: ${colors.Gray50};
      }
    `,
    'special-offer': css`
      background: ${colors.Black};

      &:hover,
      &:focus-visible {
        background: ${colors.Gray900};
      }

      &:active {
        background: ${colors.Gray800};
      }

      &[disabled] {
        background: ${colors.Gray200};
      }

      ${TextStyles} {
        font-size: 14px;
        margin: 0 auto;
        font-weight: 500 !important;
        color: ${colors.Green300};
        text-align: center;
      }
      ${StyledIcon} {
        path {
          fill: ${colors.White};
        }
        &:hover {
          path {
            fill: ${colors.White};
          }
        }
      }
    `,
    black: css`
      background: ${colors.Black};

      span {
        margin: 0 auto;
        color: ${colors.White};
        text-align: center;
      }

      &:hover,
      &:focus-visible {
        background: ${colors.Gray400};
      }

      &:active {
        background: ${colors.Gray600};
      }

      ${StyledIcon} {
        path {
          fill: ${colors.White};
        }
        &:hover {
          path {
            fill: ${colors.White};
          }
        }
      }
    `,
  })[variant]

const resolveLoaderVariant = (variant: ButtonVariants = 'primary-blue') =>
  ({
    'primary-blue': css`
      background: ${colors.Blue400};
    `,
    'primary-black': css`
      background: ${colors.Black};
    `,
    'primary-green': css`
      background: ${colors.Green400};
    `,
    secondary: css`
      background: ${colors.Gray200};
    `,
    login: css`
      background: ${colors.Blue200};
    `,
    white: css`
      background: ${colors.White};
    `,
    black: css`
      background: ${colors.Black};
    `,
    'secondary-trainer': css`
      background: ${colors.Gray200};
    `,
    'primary-trainer': css`
      background: ${colors.Blue400};
    `,
    'special-offer': css`
      background: ${colors.Black};
    `,
  })[variant]

export const ButtonLoader = styled.div<StyledButtonProps>`
  position: relative;
  width: 20px;
  height: 20px;
  margin: 20px auto;
  font-size: 10px;
  background: #fff;
  background: linear-gradient(to right, #fff 10%, rgba(255 255 255 / 0%) 42%);
  border-radius: 50%;
  transform: translateZ(0);
  animation: spinner-loader-animation 1.4s infinite linear;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 50%;
    background: #fff;
    border-radius: 100% 0 0;
    content: '';
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 75%;
    height: 75%;
    margin: auto;
    ${(props) => resolveLoaderVariant(props.$variant)};
    border-radius: 50%;
    content: '';
  }
`

export const ButtonStyles = styled.button<StyledButtonProps>`
  display: flex;
  align-items: center;
  height: 48px;
  padding-right: 24px;
  padding-left: 24px;
  border: 0;
  border-radius: 54px;
  ${(props) => resolveVariantStyle(props.$variant)};
  cursor: pointer;
  transition: 100ms background ease-in-out;
  user-select: none;
  ${StyledIcon} {
    margin-left: 8px;
  }
`
