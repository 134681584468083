import { deviceSize, useDeviceSize } from 'fitify-ui'
import { LinkTypes } from 'fitify-ui-landing/src/@types'
import NativeImage from 'fitify-ui-landing/src/components/Image/NativeImage'
import Navigation from 'fitify-ui-landing/src/components/Navigation/Navigation'
import { createRedirectLinkWithLocale } from 'fitify-ui-landing/src/utils/link'
import dynamic from 'next/dynamic'
import { useTranslation } from 'next-i18next'
import { NextSeo, type NextSeoProps, OrganizationJsonLd } from 'next-seo'

// STATIC
import { footerCopy, footerNavigation, footerSocial } from '@/data/footer'
import { businessNavigationData, navigationData } from '@/data/navbar'

const Footer = dynamic(
  () => import('fitify-ui-landing/src/components/blocks/Footer/Footer')
)
const Newsletter = dynamic(
  () => import('fitify-ui-landing/src/components/blocks/Newsletter/Newsletter')
)

const Layout = ({
  seo,
  shouldHideNewsletter,
  children,
  isBusiness = false,
  hasNoIndex = false,
  hasNoFollow = false,
}: {
  seo?: Pick<
    NextSeoProps,
    'description' | 'languageAlternates' | 'openGraph' | 'title'
  >
  shouldHideNewsletter?: boolean
  children: React.ReactNode
  isBusiness?: boolean
  hasNoIndex?: boolean
  hasNoFollow?: boolean
}) => {
  const { t, i18n } = useTranslation()
  const { windowWidth } = useDeviceSize()

  const isDesktopBreakpoint =
    windowWidth >= deviceSize.tabletLandscapeUpperBoundary

  const enhanceWithLocale = (locale: string, item: LinkTypes.Navigation) => {
    if (item.isOnboarding) {
      return {
        ...item,
        link: createRedirectLinkWithLocale(item.link, locale),
      }
    } else {
      return {
        ...item,
      }
    }
  }

  const businessNavigationItems = isDesktopBreakpoint
    ? businessNavigationData.map((item) =>
        enhanceWithLocale(i18n.language, item)
      )
    : []

  const enhancedNavigationData = navigationData.map((item) =>
    enhanceWithLocale(i18n.language, item)
  )

  const enhancedFooterNavigation = footerNavigation.map((item) =>
    enhanceWithLocale(i18n.language, item)
  )

  return (
    <>
      <NextSeo
        title={seo?.title}
        description={seo?.description}
        openGraph={seo?.openGraph}
        nofollow={hasNoFollow}
        noindex={hasNoIndex}
        languageAlternates={seo?.languageAlternates}
      />

      <OrganizationJsonLd
        type="Organization"
        logo="https://gofitify.com/assets/images/logo.svg"
        legalName="Fitify Workouts s.r.o."
        name="Fitify Workouts"
        description={
          'We develop fitness and health apps that lead you to a better lifestyle.'
        }
        foundingDate={'2018-03-08'}
        address={{
          streetAddress: 'Na Perštýně 342/1',
          addressLocality: 'Prague',
          postalCode: '110 00',
          addressCountry: 'CZ',
        }}
        contactPoint={[
          {
            contactType: 'customer service',
            email: 'support@gofitify.com',
          },
        ]}
        url="https://gofitify.com"
      />

      <Navigation
        items={isBusiness ? businessNavigationItems : enhancedNavigationData}
        options={{ mobileEnabled: false }}
        logo={
          <NativeImage
            src={'/assets/images/logo.webp'}
            srcSet={'/assets/images/logo.webp, /assets/images/logo@2x.webp 2x'}
            alt={'Fitify Logo'}
            width={'130'}
            height={'40'}
          />
        }
      />

      <div>
        {children}

        {!shouldHideNewsletter && (
          <Newsletter
            blockText="landing_emailing_block_text"
            emailPlaceholderText="landing_emailing_block_your_email"
            submitButtonText="landing_emailing_block_button"
            successMessage="landing_newsletter_success_messages"
            errorMessage="landing_newsletter_error"
          />
        )}

        <Footer
          companyName={`${t('landing_contact_us_company_name')}`}
          copyLinks={footerCopy}
          socialLinks={footerSocial}
          navigationLinks={enhancedFooterNavigation}
          logo={
            <NativeImage
              src={'/assets/images/logo-icon.webp'}
              srcSet={
                '/assets/images/logo-icon.webp, /assets/images/logo-icon@2x.webp 2x'
              }
              alt={'Fitify Logo'}
              width={'88'}
              height={'88'}
            />
          }
        />
      </div>
    </>
  )
}

export default Layout
