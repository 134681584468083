import { StyledIcon } from '../Icon.Styled'

export const ArrowDoubleBigRightIcon = (props: any) => {
  return (
    <StyledIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4 23.4139L2.586 21.9999L12.586 11.9999L2.586 1.99994L4 0.585938L14.707 11.2929C15.098 11.6839 15.098 12.3159 14.707 12.7069L4 23.4139Z"
        fill="currentColor"
      />
      <path
        d="M10 23.4139L8.586 21.9999L18.586 11.9999L8.586 1.99994L10 0.585938L20.707 11.2929C21.098 11.6839 21.098 12.3159 20.707 12.7069L10 23.4139Z"
        fill="currentColor"
      />
    </StyledIcon>
  )
}
