import { StyledIcon } from '../Icon.Styled'

export const QuotesIcon = (props: any) => {
  return (
    <StyledIcon
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      {...props}
    >
      <g filter="url(#b)" clipPath="url(#a)">
        <path
          d="M35.527 18.5c0-9.404-7.623-17.027-17.027-17.027S1.473 9.096 1.473 18.5 9.096 35.527 18.5 35.527 35.527 27.904 35.527 18.5Z"
          fill="#0C3D7D"
        />
        <path
          d="M36 18.5C36 8.835 28.165 1 18.5 1S1 8.835 1 18.5 8.835 36 18.5 36 36 28.165 36 18.5Z"
          stroke="#134A90"
        />
        <path
          d="M14.4 13.455a2.838 2.838 0 0 0 0 5.676c.014 0 .026-.004.039-.004a15 15 0 0 1-1.525 4.094.63.63 0 0 0 .987.77c.136-.136 3.338-3.384 3.338-7.383a.609.609 0 0 0-.022-.105c.006-.07.022-.138.022-.21a2.842 2.842 0 0 0-2.838-2.838ZM25.437 16.293a2.838 2.838 0 1 0-2.838 2.838c.013 0 .025-.004.038-.004a15.003 15.003 0 0 1-1.525 4.094.63.63 0 0 0 .987.77c.136-.136 3.338-3.384 3.338-7.383a.602.602 0 0 0-.022-.105c.006-.07.022-.138.022-.21Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="currentColor" d="M0 0h37v37H0z" />
        </clipPath>
        <filter
          id="b"
          x="-7.5"
          y="-5.5"
          width="52"
          height="52"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_106_36"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_106_36"
            result="shape"
          />
        </filter>
      </defs>
    </StyledIcon>
  )
}
