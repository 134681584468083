import { StyledIcon } from '../Icon.Styled'

export const JumpingJackIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12 12"
    width="12"
    height="12"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.221 2.046a1.22 1.22 0 1 1-2.442 0 1.22 1.22 0 0 1 2.442 0ZM2.731.976a.733.733 0 0 0-.61.838c.258 1.633 1.115 2.657 2.147 3.343.306.203.51.534.51.901v.543c0 .284-.122.554-.327.75-.957.91-1.602 1.776-2.232 2.67a.733.733 0 1 0 1.198.843c.581-.825 1.145-1.58 1.95-2.362a.906.906 0 0 1 1.266 0c.806.783 1.37 1.537 1.95 2.362a.733.733 0 1 0 1.198-.844c-.63-.893-1.275-1.758-2.231-2.67a1.04 1.04 0 0 1-.328-.749v-.543c0-.367.205-.698.51-.901 1.032-.686 1.89-1.71 2.147-3.343a.733.733 0 1 0-1.447-.228c-.214 1.355-.987 2.124-2.04 2.659a.867.867 0 0 1-.784 0c-1.052-.535-1.825-1.304-2.04-2.66A.733.733 0 0 0 2.73.977ZM.75 5.003a2.325 2.325 0 0 1 .317-1.277.366.366 0 1 1 .634.366c-.186.325-.309.698-.309 1.043 0 .346.097.766.184 1.061.039.131 0 .238-.063.275-.064.036-.178.056-.26-.053C.957 6.018.76 5.51.75 5.003Zm10.183-1.277c.253.44.324.835.317 1.277-.008.507-.206 1.014-.504 1.415-.082.11-.196.089-.26.052-.062-.036-.101-.143-.063-.274.087-.295.184-.715.184-1.06 0-.347-.122-.72-.309-1.044a.366.366 0 1 1 .635-.366Z"
        fill="#020A15"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h12v12H0z" />
      </clipPath>
    </defs>
  </StyledIcon>
)
