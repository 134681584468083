import { StyledIcon } from '../Icon.Styled'

export const Hamburger = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="square"
      strokeMiterlimit="10"
      strokeWidth="2"
      d="M1 12h22M1 5h22M1 19h11"
    />
  </StyledIcon>
)
