import { StyledIcon } from '../Icon.Styled'

export const WeightScaleIcon = (props: any) => {
  return (
    <StyledIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21 1H3C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V21C1 21.5304 1.21071 22.0391 1.58579 22.4142C1.96086 22.7893 2.46957 23 3 23H21C21.5304 23 22.0391 22.7893 22.4142 22.4142C22.7893 22.0391 23 21.5304 23 21V3C23 2.46957 22.7893 1.96086 22.4142 1.58579C22.0391 1.21071 21.5304 1 21 1ZM19 13H5C4.73478 13 4.48043 12.8946 4.29289 12.7071C4.10536 12.5196 4 12.2652 4 12C4 9.87827 4.84285 7.84344 6.34315 6.34315C7.84344 4.84285 9.87827 4 12 4C14.1217 4 16.1566 4.84285 17.6569 6.34315C19.1571 7.84344 20 9.87827 20 12C20 12.2652 19.8946 12.5196 19.7071 12.7071C19.5196 12.8946 19.2652 13 19 13Z"
        fill="currentColor"
      />
      <path
        d="M13.658 6.0257C13.5585 5.9925 13.4511 5.99144 13.3509 6.02267C13.2508 6.0539 13.163 6.11583 13.1 6.1997L10.1 10.1997C10.0443 10.274 10.0104 10.3623 10.002 10.4548C9.99368 10.5473 10.0113 10.6403 10.0528 10.7233C10.0943 10.8064 10.1581 10.8762 10.2371 10.925C10.3161 10.9738 10.4071 10.9997 10.5 10.9997H13.5C13.6326 10.9997 13.7598 10.947 13.8536 10.8533C13.9473 10.7595 14 10.6323 14 10.4997V6.4997C13.9999 6.3948 13.9669 6.29258 13.9055 6.20752C13.8441 6.12245 13.7575 6.05884 13.658 6.0257Z"
        fill="currentColor"
      />
    </StyledIcon>
  )
}
