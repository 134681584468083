import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ACTIVE_PROMO_STORE } from '../..'

export const createRedirectLinkWithLocale = (
  url: string,
  locale?: string,
  queryParams?: Record<string, string>
): string => {
  if (!url) return ''

  // Construct the base URL with the locale
  let newUrl = new URL(url)
  if (locale) {
    const localeUrl = locale === 'en' ? '' : `/${locale}`
    newUrl = new URL(`${newUrl.origin}${localeUrl}${newUrl.pathname}`)
  }

  // Check for 'promo' query parameter in the current URL, if window is defined
  const currentUrlParams = new URLSearchParams(
    typeof window !== 'undefined' ? window?.location.search : undefined
  )
  const promoParam =
    currentUrlParams.get('promo') ||
    (typeof window !== 'undefined' &&
      window.localStorage.getItem(ACTIVE_PROMO_STORE)) ||
    process.env.NEXT_PUBLIC_DEFAULT_PROMO

  if (promoParam) {
    // Add 'promo' query parameter to the new URL
    newUrl.searchParams.set('promo', promoParam)
  }

  // Add additional query parameters if any
  if (queryParams) {
    for (const [key, value] of Object.entries(queryParams)) {
      newUrl.searchParams.set(key, value)
    }
  }

  return newUrl.toString()
}

export const redirectWithLocale = (
  url: string,
  locale?: string,
  target?: string,
  queryParams?: Record<string, string>
): void => {
  const redirectUrl = createRedirectLinkWithLocale(url, locale, queryParams)

  window.open(redirectUrl, target)
}

export const usePromoLink = (baseUrl: string) => {
  const router = useRouter()
  const { i18n } = useTranslation()
  const [promo, setPromo] = useState('')
  const [link, setLink] = useState('')

  // Set promo on client side
  useEffect(() => {
    if (typeof window !== 'undefined' && window.localStorage) {
      setPromo(window.localStorage.getItem(ACTIVE_PROMO_STORE) || '')
    }
  }, [])

  // Add local storage renewal listener
  useEffect(() => {
    const renewStoragePromo = () => {
      if (typeof window !== 'undefined' && window.localStorage) {
        setPromo(window.localStorage.getItem(ACTIVE_PROMO_STORE) || '')
      }
    }

    window.addEventListener('storage', renewStoragePromo)
    return () => {
      window.removeEventListener('storage', renewStoragePromo)
    }
  }, [])

  // Add URL query renewal listener
  useEffect(() => {
    if (router.query.promo) {
      setPromo(router.query.promo as string)
    }
  }, [router.query.promo])

  useEffect(() => {
    if (baseUrl) {
      setLink(
        createRedirectLinkWithLocale(
          baseUrl,
          i18n.language,
          promo
            ? {
                promo: promo,
              }
            : {}
        )
      )
    }
  }, [baseUrl, i18n.language, promo])

  return link
}
