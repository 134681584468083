import { StyledIcon } from '../Icon.Styled'

export const ArrowDoubleBigLeftIcon = (props: any) => {
  return (
    <StyledIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 23.4139L9.293 12.7069C8.902 12.3159 8.902 11.6839 9.293 11.2929L20 0.585938L21.414 1.99994L11.414 11.9999L21.414 21.9999L20 23.4139Z"
        fill="currentColor"
      />
      <path
        d="M14 23.4139L3.293 12.7069C2.902 12.3159 2.902 11.6839 3.293 11.2929L14 0.585938L15.414 1.99994L5.414 11.9999L15.414 21.9999L14 23.4139Z"
        fill="currentColor"
      />
    </StyledIcon>
  )
}
