import { StyledIcon } from '../Icon.Styled'

export const MuscleIcon = (props: any) => {
  return (
    <StyledIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.683 11.414c-3.268-1.73-8.089-.23-10.5 1.52l-.094-.142a8.471 8.471 0 0 1-1.34-2.509 19.592 19.592 0 0 1-.193-2.394c.11-.006.219-.016.328-.032l.097-.02a5.43 5.43 0 0 0 2.678-1.725c.23-.331.366-.719.39-1.12.023-.4-.066-.797-.256-1.147a4.389 4.389 0 0 0 .232-1.532A1.978 1.978 0 0 0 10.34.802 5.925 5.925 0 0 0 6.692 0C3.66 0 1.832 6.45 1.014 10.295c-1.084 5.08.424 10.022 1.507 11.14 1.52 1.576 4.804 1.416 5.788 1.328 1.489.455 3.048.655 4.61.591.249 0 .504 0 .763-.01 2.44 0 4.846-.663 6.958-1.92a5.637 5.637 0 0 0 1.735-2.028 5.56 5.56 0 0 0 .6-2.59 6.623 6.623 0 0 0-.96-3.122 6.558 6.558 0 0 0-2.332-2.27Zm-1.204 5.756a9.658 9.658 0 0 1-4.95 1.348c-.82 0-1.634-.098-2.428-.292a.717.717 0 0 1-.533-.882.745.745 0 0 1 .898-.536 8.362 8.362 0 0 0 6.28-.89.735.735 0 0 1 .549-.073.715.715 0 0 1 .511.877.747.747 0 0 1-.329.448h.002Z"
        fill="currentColor"
      />
      <defs>
        <clipPath id="clip0_211_277">
          <path fill="currentColor" d="M0 0h24v24H0z" />
        </clipPath>
        <clipPath id="clip1_211_277">
          <path
            fill="currentColor"
            transform="matrix(1 0 -.01597 .99987 .373 0)"
            d="M0 0h23.357v23.357H0z"
          />
        </clipPath>
      </defs>
    </StyledIcon>
  )
}
