import { StyledIcon } from '../Icon.Styled'

export const BarbellToolIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <g fill="currentColor" clipPath="url(#barbell)">
      <path d="M15.999 6.586 6.585 16 8 17.414 17.413 8 16 6.587ZM19.914 5.5l3.5-3.5L22 .586l-3.5 3.5-2.793-2.793a1 1 0 0 0-1.414 1.414l7 7a1 1 0 0 0 1.414-1.414L19.914 5.5ZM2.707 14.293a1 1 0 0 0-1.414 1.414L4.086 18.5.586 22 2 23.414l3.5-3.5 2.793 2.793a1 1 0 0 0 1.414-1.414l-7-7Z" />
    </g>
    <defs>
      <clipPath id="barbell">
        <path fill="currentColor" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </StyledIcon>
)
