import { StyledIcon } from '../Icon.Styled'

export const ArrowLeftIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width="16"
    height="16"
    {...props}
  >
    <path
      fill="currentColor"
      d="M13.333 7.333H5.22l3.727-3.727L8 2.667 2.667 8 8 13.333l.94-.94-3.72-3.727h8.113V7.333Z"
    />
  </StyledIcon>
)
