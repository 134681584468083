import { StyledIcon } from '../Icon.Styled'

export const FitifyHcLogoPictogram = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="144"
    height="81"
    viewBox="0 0 144 81"
    fill="none"
    {...props}
  >
    <path
      fill="#95EB00"
      fillRule="evenodd"
      d="M103.807 3.47H40.193C19.91 3.47 3.47 19.91 3.47 40.193c0 20.281 16.441 36.723 36.723 36.723h63.614c20.282 0 36.723-16.442 36.723-36.723 0-20.282-16.441-36.723-36.723-36.723ZM40.193 0C17.995 0 0 17.995 0 40.193 0 62.39 17.995 80.385 40.193 80.385h63.614c22.198 0 40.193-17.994 40.193-40.192S126.005 0 103.807 0H40.193Z"
      clipRule="evenodd"
    />
    <path
      fill="#95EB00"
      d="M21.805 52.555h5.1v-8.64h6.524c6.627 0 11.173-2.672 11.173-8.224v-.139c0-5.447-4.442-7.807-11.347-7.807h-11.45v24.81Zm5.1-12.11v-9.16h6.42c3.712 0 6.21 1.214 6.21 4.406v.14c0 3.053-2.29 4.614-6.21 4.614h-6.42ZM47.471 52.555h20.056V48.91H52.572V27.745h-5.1v24.81ZM82.316 52.902c8.64 0 13.116-3.713 13.116-11.208V27.745h-5.1v13.672c0 5.968-2.95 7.807-7.912 7.807-5.205 0-7.946-2.117-7.946-7.46V27.745h-5.1v14.227c0 7.356 4.718 10.93 12.942 10.93ZM111.545 52.902c7.773 0 12.145-3.192 12.145-7.738 0-5.1-4.164-6.732-11.798-7.46-6.107-.66-7.599-1.631-7.599-3.47 0-2.082 1.978-3.331 6.454-3.331 4.198 0 6.454 1.04 7.113 3.678h4.823c-.659-4.858-4.996-7.148-11.971-7.148-7.078 0-11.277 2.915-11.277 7.078 0 4.65 3.643 6.385 11.277 7.218 5.968.66 8.016 1.388 8.016 3.782 0 2.498-2.603 3.921-7.218 3.921-5.76 0-7.772-1.943-8.223-4.546H98.29c.555 4.72 4.615 8.016 13.255 8.016Z"
    />
  </StyledIcon>
)
