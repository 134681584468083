import { StyledIcon } from '../Icon.Styled'

export const TrashBinIconV2 = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width={10}
    height={10}
    fill="none"
    viewBox="0 0 10 10"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M2.917.833v.834H0V2.5h10v-.833H7.083V0H2.917v.833Zm3.333.417v.417h-2.5V.833h2.5v.417ZM1.658 3.335l-.408.03c-.023.002-.001.348.169 2.73.107 1.502.2 2.775.206 2.83.028.252.16.513.358.708.194.19.4.299.65.343.185.034 4.55.034 4.734 0a1.19 1.19 0 0 0 .65-.343c.198-.195.33-.455.358-.708.007-.055.1-1.328.206-2.83.17-2.382.192-2.728.17-2.73l-.413-.029a4.433 4.433 0 0 0-.388-.02l-.198 2.767c-.159 2.209-.204 2.772-.226 2.825a.442.442 0 0 1-.218.22l-.083.038h-4.45l-.083-.039a.442.442 0 0 1-.218-.219c-.022-.053-.067-.617-.226-2.831-.108-1.521-.2-2.766-.202-2.768-.002-.001-.177.01-.388.026Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h10v10H0z" />
      </clipPath>
    </defs>
  </StyledIcon>
)
