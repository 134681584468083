import { StyledIcon } from '../Icon.Styled'

export const StatusWarningIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    {...props}
  >
    <path
      fill="currentColor"
      d="M6 0C2.692 0 0 2.692 0 6c0 3.309 2.692 6 6 6 3.309 0 6-2.691 6-6 0-3.308-2.691-6-6-6Zm.822 2.5L6.5 7h-1l-.304-4.5h1.627ZM6 10a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
    />
  </StyledIcon>
)
