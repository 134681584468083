import { StyledIcon } from '../Icon.Styled'

export const SignalIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="7"
    height="8"
    viewBox="0 0 7 8"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_11707_13812)">
      <path
        d="M2.99984 7.33268V2.66602H4.33317V7.33268H2.99984ZM5.33317 7.33268V0.666016H6.6665V7.33268H5.33317ZM0.666504 7.33268V4.66602H1.99984V7.33268H0.666504Z"
        fill="currentColor"
      />
      <path
        d="M2.99984 7.33268V2.66602H4.33317V7.33268H2.99984ZM5.33317 7.33268V0.666016H6.6665V7.33268H5.33317ZM0.666504 7.33268V4.66602H1.99984V7.33268H0.666504Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_11707_13812">
        <rect width="7" height="8" fill="white" />
      </clipPath>
    </defs>
  </StyledIcon>
)
