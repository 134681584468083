import { StyledIcon } from '../Icon.Styled'

export const FlashIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <g clipPath="url(#flash-icon)">
      <path
        fill="currentColor"
        d="M21.897 9.55768C21.729 9.21568 21.381 8.99969 21 8.99969H13.117L13.993 1.10968C14.043 0.663685 13.789 0.239685 13.373 0.0716849C12.956 -0.0983151 12.479 0.0336849 12.206 0.388685L2.20599 13.3887C1.97399 13.6907 1.93299 14.0987 2.10199 14.4407C2.27099 14.7837 2.61899 14.9997 2.99999 14.9997H10.883L10.007 22.8897C9.95699 23.3357 10.211 23.7597 10.627 23.9277C10.748 23.9767 10.875 23.9997 11 23.9997C11.304 23.9997 11.6 23.8607 11.793 23.6097L21.793 10.6097C22.025 10.3077 22.065 9.89968 21.897 9.55768Z"
      />
    </g>
    <defs>
      <clipPath id="flash-icon">
        <path fill="#fff" d="M0 0H24V24H0z" />
      </clipPath>
    </defs>
  </StyledIcon>
)
