import { StyledIcon } from '../Icon.Styled'

export const CaloriesIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="10"
    viewBox="0 0 8 10"
    fill="none"
    {...props}
  >
    <path
      d="M7.11699 5.14587c-.65417-1.7-2.98333-1.79167-2.42083-4.2625.04167-.18333-.15417-.325-.3125-.22916-1.5125.89166-2.6 2.67916-1.6875 5.02083.075.19166-.15.37083-.3125.24583-.75417-.57083-.83333-1.39167-.76667-1.97917.025-.21666-.25833-.32083-.37916-.14166-.28334.43333-.57084 1.13333-.57084 2.1875.15834 2.33333 2.12917 3.05 2.8375 3.14167 1.0125.12916 2.10833-.05834 2.89584-.77917.86666-.80417 1.18333-2.0875.71666-3.20417ZM3.25033 7.2417c.6-.14583.90833-.57916.99166-.9625.1375-.59583-.4-1.17916-.0375-2.12083.1375.77917 1.3625 1.26667 1.3625 2.11667.03334 1.05417-1.10833 1.95833-2.31666.96666Z"
      fill="currentColor"
    />
  </StyledIcon>
)
