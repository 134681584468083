import { StyledIcon } from '../Icon.Styled'

export const ClockBigIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M7.99 1v1.002h3.007v1h2.005v-1h3.007V0H7.99v1Zm3.101 3.044a10.046 10.046 0 0 0-5.778 2.521 10.021 10.021 0 0 0-3.133 5.466c-.14.698-.18 1.139-.18 1.981 0 1.057.096 1.794.363 2.743a10.148 10.148 0 0 0 3.193 4.916 9.97 9.97 0 0 0 4.98 2.236c.931.117 1.873.124 2.806.02 3.567-.524 6.454-2.718 7.865-5.973a9.422 9.422 0 0 0 .792-3.942c0-.888-.054-1.429-.217-2.164a10.018 10.018 0 0 0-5.348-6.802 9.87 9.87 0 0 0-3.192-.962 14.889 14.889 0 0 0-2.15-.04Zm1.744 2.003a8.34 8.34 0 0 1 3.412 1.171 8.494 8.494 0 0 1 2.426 2.35 8.026 8.026 0 0 1-.257 9.249 8.926 8.926 0 0 1-1.964 1.857 8.07 8.07 0 0 1-8.901 0 8.927 8.927 0 0 1-1.969-1.857A8.024 8.024 0 0 1 5.19 9.78c.387-.633 1.085-1.426 1.718-1.952a8.265 8.265 0 0 1 4.21-1.78 10.05 10.05 0 0 1 1.718-.002Zm-1.838 5.964v3.002h6.014v-2.002h-4.01V9.008h-2.004v3.003Z"
    />
  </StyledIcon>
)
