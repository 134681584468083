import { StyledIcon } from '../Icon.Styled'

export const StepsIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M18 0C15.1 0 13 2.6 13 6 13 7.2 13.2 8 13.4 8.9 13.7 9.9 14 11 14 13 14 13.6 14.4 14 15 14H21C21.5 14 21.9 13.7 22 13.2 22 13 23 9 23 6 23 2.6 20.9 0 18 0ZM6 2C3.1 2 1 4.6 1 8 1 11.1 2 15.1 2 15.2 2.1 15.7 2.5 16 3 16H9C9.6 16 10 15.6 10 15 10 13 10.3 11.9 10.6 10.9 10.8 10 11 9.2 11 8 11 4.6 8.9 2 6 2ZM9.3 18H2.7C2.3 18 1.9 18.3 1.8 18.7 1.6 19.5 1.6 20.4 1.9 21.2 2.7 23 4.3 24 6 24 7.6 24 9.1 23.1 9.8 21.6 10.2 20.7 10.3 19.7 10.1 18.7 10.1 18.3 9.7 18 9.3 18ZM21.3 16H14.7C14.3 16 13.9 16.3 13.8 16.7 13.6 17.5 13.6 18.4 13.9 19.2 14.6 20.9 16.2 22 17.9 22 19.5 22 21 21.1 21.7 19.6 22.1 18.7 22.2 17.7 22 16.7 22.1 16.3 21.7 16 21.3 16Z"
    />
  </StyledIcon>
)
