import { StyledIcon } from '../Icon.Styled'

export const BicepFillIcon = (props: any) => {
  return (
    <StyledIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.028 11.7284C16.642 9.95144 11.713 11.4914 9.26501 13.2904L9.16501 13.1444C8.55227 12.3699 8.07303 11.4986 7.74701 10.5664C7.61792 9.75204 7.53877 8.9305 7.51001 8.10644C7.62201 8.10044 7.73401 8.08944 7.84601 8.07344L7.94601 8.05344C9.01249 7.7523 9.96218 7.13394 10.669 6.28044C10.9007 5.93979 11.0328 5.54127 11.0505 5.12966C11.0682 4.71806 10.9707 4.30969 10.769 3.95044C10.9336 3.44239 11.0061 2.909 10.983 2.37544C10.9789 2.07952 10.9117 1.78789 10.7858 1.52007C10.6599 1.25224 10.4783 1.01439 10.253 0.822438C9.11981 0.152455 7.80146 -0.136033 6.49201 -0.000561606C3.37701 -0.000561606 1.60501 6.62744 0.827005 10.5784C-0.202995 15.7984 1.42701 20.8784 2.55801 22.0274C4.14701 23.6464 7.51801 23.4824 8.52801 23.3914C10.0649 23.8595 11.6697 24.0651 13.275 23.9994C13.53 23.9994 13.792 23.9994 14.058 23.9894C16.5657 23.9894 19.0261 23.3074 21.176 22.0164C21.9203 21.4742 22.521 20.7584 22.926 19.9314C23.331 19.1043 23.528 18.1909 23.5 17.2704C23.4248 16.1316 23.0682 15.0292 22.4623 14.062C21.8563 13.0948 21.0199 12.293 20.028 11.7284ZM18.886 17.6434C17.3554 18.5571 15.6046 19.0363 13.822 19.0294C12.9797 19.0291 12.1405 18.9284 11.322 18.7294C11.2252 18.7073 11.1338 18.6662 11.0531 18.6085C10.9723 18.5508 10.9038 18.4776 10.8516 18.3932C10.7993 18.3089 10.7643 18.2149 10.7486 18.1169C10.7329 18.0189 10.7368 17.9188 10.7601 17.8223C10.7834 17.7258 10.8256 17.6349 10.8843 17.5549C10.943 17.4748 11.017 17.4072 11.102 17.356C11.187 17.3047 11.2813 17.2709 11.3795 17.2564C11.4777 17.2418 11.5778 17.247 11.674 17.2714C13.8567 17.8277 16.1707 17.4988 18.112 16.3564C18.2826 16.2592 18.4844 16.2326 18.6744 16.2824C18.8643 16.3322 19.0272 16.4544 19.1281 16.6228C19.229 16.7912 19.26 16.9924 19.2144 17.1834C19.1688 17.3743 19.0502 17.5398 18.884 17.6444L18.886 17.6434Z"
        fill="currentColor"
      />
    </StyledIcon>
  )
}
