import { StyledIcon } from '../Icon.Styled'

export const XSocialIcon = (props: any) => (
  <StyledIcon
    viewBox="0 0 24 24"
    height="24"
    width="24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.901 1h3.68l-8.04 9.319L24 23h-7.406l-5.8-7.691L4.155 23H.474l8.6-9.968L0 1h7.594l5.243 7.03L18.901 1ZM17.61 20.766h2.039L6.486 3.116H4.298l13.312 17.65Z"
      fill="currentColor"
    />
  </StyledIcon>
)
