import { StyledIcon } from '../Icon.Styled'

export const CalendarRepeat = ({ color, ...props }: any) => {
  return (
    <StyledIcon
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      color={color || 'Gray700'}
    >
      <path
        d="M5.9375 1.5625V0.9375C5.9375 0.85462 5.90458 0.775134 5.84597 0.716529C5.78737 0.657924 5.70788 0.625 5.625 0.625C5.54212 0.625 5.46263 0.657924 5.40403 0.716529C5.34542 0.775134 5.3125 0.85462 5.3125 0.9375V1.5625H5.9375Z"
        fill="currentColor"
      />
      <path
        d="M2.1875 1.5625V0.9375C2.1875 0.85462 2.15458 0.775134 2.09597 0.716529C2.03737 0.657924 1.95788 0.625 1.875 0.625C1.79212 0.625 1.71263 0.657924 1.65403 0.716529C1.59542 0.775134 1.5625 0.85462 1.5625 0.9375V1.5625H2.1875Z"
        fill="currentColor"
      />
      <mask
        id="mask0_20785_3871"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="-1"
        y="0"
        width="10"
        height="9"
      >
        <path
          d="M7.83321 3.13375C3.72246 2.79119 3.28131 6.24999 3.54173 7.70832L0.162844 8.09927L-0.305906 1.22427L3.60034 0.286771C6.72535 -0.494526 10.3332 3.34209 7.83321 3.13375Z"
          fill="currentColor"
        />
      </mask>
      <g mask="url(#mask0_20785_3871)">
        <path
          d="M6.5625 7.5H0.9375C0.68886 7.5 0.450403 7.40123 0.274587 7.22541C0.098772 7.0496 0 6.81114 0 6.5625L0 2.5C0 2.25136 0.098772 2.0129 0.274587 1.83709C0.450403 1.66127 0.68886 1.5625 0.9375 1.5625H6.5625C6.81114 1.5625 7.0496 1.66127 7.22541 1.83709C7.40123 2.0129 7.5 2.25136 7.5 2.5V6.5625C7.5 6.81114 7.40123 7.0496 7.22541 7.22541C7.0496 7.40123 6.81114 7.5 6.5625 7.5ZM6.875 3.125H0.625V6.5625C0.625 6.64538 0.657924 6.72487 0.716529 6.78347C0.775134 6.84208 0.85462 6.875 0.9375 6.875H6.5625C6.64538 6.875 6.72487 6.84208 6.78347 6.78347C6.84208 6.72487 6.875 6.64538 6.875 6.5625V3.125Z"
          fill="currentColor"
        />
      </g>
      <g clipPath="url(#clip0_20785_3871)">
        <path
          d="M7.03125 4.25781C7.57512 4.25781 8.09027 4.43555 8.51602 4.75902L7.53055 5.6868L10.0371 5.95559L9.9193 3.4375L8.91988 4.37867C8.38668 3.94664 7.72715 3.71094 7.03125 3.71094C5.37285 3.71094 4.02344 5.06035 4.02344 6.71875H4.57031C4.57031 5.36168 5.67418 4.25781 7.03125 4.25781Z"
          fill="currentColor"
        />
        <path
          d="M7.03129 9.17969C6.48742 9.17969 5.97227 9.00195 5.54652 8.67848L6.53199 7.7507L4.02539 7.48191L4.14324 10L5.14266 9.05883C5.67586 9.49086 6.33539 9.72656 7.03129 9.72656C8.68969 9.72656 10.0391 8.37715 10.0391 6.71875H9.49223C9.49223 8.07582 8.38836 9.17969 7.03129 9.17969Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_20785_3871">
          <rect
            width="6.5625"
            height="6.5625"
            fill="white"
            transform="translate(3.75 3.4375)"
          />
        </clipPath>
      </defs>
    </StyledIcon>
  )
}
