import { StyledIcon } from '../Icon.Styled'

export const DataUploadIcon = (props: any) => {
  return (
    <StyledIcon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.28 4.29961L4.58 7.99961L5.29 8.70961L6 9.41961L8.5 6.91961L11 4.41961V16.9996H13V4.41961L15.5 6.91961L18 9.41961L18.71 8.70961L19.42 7.99961L15.72 4.29961C14.4866 3.05967 13.2466 1.82632 12 0.599609C11.989 0.599609 10.315 2.26461 8.28 4.29961ZM1 18.9996V22.9996H23V14.9996H21V20.9996H3V14.9996H1V18.9996Z"
        fill="currentColor"
      />
    </StyledIcon>
  )
}
