import { StyledIcon } from '../Icon.Styled'

export const ArrowBigRightIcon = (props: any) => {
  return (
    <StyledIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 23.4139L6.586 21.9999L16.586 11.9999L6.586 1.99994L8 0.585938L18.707 11.2929C19.098 11.6839 19.098 12.3159 18.707 12.7069L8 23.4139Z"
        fill="currentColor"
      />
    </StyledIcon>
  )
}
