import { StyledIcon } from '../Icon.Styled'

export const AddSmallIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    {...props}
  >
    <g clipPath="url(#clip0_15142_20062)">
      <path
        fill="currentColor"
        d="M6 0a6 6 0 1 0 6 6 6.017 6.017 0 0 0-6-6Zm3 6.5H6.5V9h-1V6.5H3v-1h2.5V3h1v2.5H9v1Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_15142_20062">
        <path fill="#fff" d="M0 0h12v12H0z" />
      </clipPath>
    </defs>
  </StyledIcon>
)
