import { StyledIcon } from '../Icon.Styled'

export const ChevronDownIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M7.333.667H.667A.668.668 0 0 0 .1 1.687L3.435 7.02a.667.667 0 0 0 1.13 0L7.9 1.687a.667.667 0 0 0-.566-1.02Z"
    />
  </StyledIcon>
)
