import { StyledIcon } from '../Icon.Styled'

export const StarFullIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="15"
    viewBox="0 0 16 15"
    {...props}
  >
    <path
      d="M7.50977 1.02314C7.66406 0.548276 8.33586 0.548276 8.49015 1.02314L9.86797 5.26373C9.93697 5.47609 10.1349 5.61988 10.3582 5.61988H14.8169C15.3162 5.61988 15.5238 6.2588 15.1198 6.55228L11.5127 9.17311C11.332 9.30436 11.2564 9.53701 11.3254 9.74937L12.7032 13.99C12.8575 14.4648 12.314 14.8597 11.9101 14.5662L8.30291 11.9454C8.12227 11.8141 7.87765 11.8141 7.69701 11.9454L4.08982 14.5662C3.68588 14.8597 3.14239 14.4648 3.29668 13.99L4.6745 9.74937C4.7435 9.53701 4.66791 9.30436 4.48727 9.17311L0.880075 6.55228C0.476138 6.2588 0.683734 5.61988 1.18303 5.61988H5.64176C5.86505 5.61988 6.06295 5.47609 6.13195 5.26373L7.50977 1.02314Z"
      fill="currentColor"
    />
  </StyledIcon>
)
