import { StyledIcon } from '../Icon.Styled'

export const SunIcon = (props: any) => {
  return (
    <StyledIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M3 11H0V13H3V11Z" fill="currentColor" />
      <path
        d="M4.22133 2.80748L2.80713 4.22168L4.92843 6.34298L6.34263 4.92878L4.22133 2.80748Z"
        fill="currentColor"
      />
      <path d="M13 0H11V3H13V0Z" fill="currentColor" />
      <path
        d="M19.7782 2.80741L17.6569 4.92871L19.0711 6.34291L21.1924 4.22161L19.7782 2.80741Z"
        fill="currentColor"
      />
      <path d="M24 11H21V13H24V11Z" fill="currentColor" />
      <path
        d="M19.0713 17.6571L17.6571 19.0713L19.7784 21.1926L21.1926 19.7784L19.0713 17.6571Z"
        fill="currentColor"
      />
      <path d="M13 21H11V24H13V21Z" fill="currentColor" />
      <path
        d="M4.92855 17.657L2.80725 19.7783L4.22145 21.1925L6.34275 19.0712L4.92855 17.657Z"
        fill="currentColor"
      />
      <path
        d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19Z"
        fill="currentColor"
      />
    </StyledIcon>
  )
}
