import { useRouter } from 'next/router'
import { useEffect } from 'react'

const useScrollToHash = () => {
  const router = useRouter()

  useEffect(() => {
    let timeoutId: NodeJS.Timeout

    if (window.location.hash) {
      const elementId = window.location.hash.substring(1)
      const element = document.getElementById(elementId)

      if (element) {
        const scrollToElement = () => {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          })
        }

        timeoutId = setTimeout(scrollToElement, 100)
      }
    }

    return () => {
      clearTimeout(timeoutId)
    }
  }, [router])
}

export default useScrollToHash
