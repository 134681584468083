import { StyledIcon } from '../Icon.Styled'

export const Trophy = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="38"
    height="40"
    viewBox="0 0 38 40"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M35.667 0H2.333A1.667 1.667 0 0 0 .667 1.667V10a6.667 6.667 0 0 0 6.666 6.667h.134a11.667 11.667 0 0 0 9.866 9.866v5.134h3.334v-5.134a11.666 11.666 0 0 0 9.866-9.866h.134A6.667 6.667 0 0 0 37.333 10V1.667A1.667 1.667 0 0 0 35.667 0ZM4 10V3.333h3.333v10A3.333 3.333 0 0 1 4 10Zm30 0a3.333 3.333 0 0 1-3.333 3.333v-10H34V10ZM22.334 33.333h-6.667C7.333 33.333 7.333 40 7.333 40h23.334s0-6.667-8.334-6.667Z"
    />
  </StyledIcon>
)
