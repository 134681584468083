import { StyledIcon } from '../Icon.Styled'

export const EditIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.734.5c.464 0 .91.179 1.247.5a1.765 1.765 0 0 1 0 2.49l-.357.36a.25.25 0 0 1-.354 0L8.13 1.71a.25.25 0 0 1 0-.354l.357-.357C8.823.68 9.27.5 9.734.5ZM7.59 2.246a.257.257 0 0 0-.353 0L2.53 6.953a.25.25 0 0 0 0 .353l2.14 2.14a.25.25 0 0 0 .354 0L9.73 4.741a.25.25 0 0 0 0-.354l-2.14-2.14Zm-5.593 5.6a.25.25 0 0 0-.414.1l-1.07 3.21a.25.25 0 0 0 .316.316l3.21-1.069a.25.25 0 0 0 .1-.414L1.997 7.846Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="currentColor" d="M0 0h12v12H0z" />
      </clipPath>
    </defs>
  </StyledIcon>
)
