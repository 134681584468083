import { StyledIcon } from '../Icon.Styled'

export const PinterestIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    {...props}
    fill="none"
  >
    <g clipPath="url(#clip0_3155_47228)">
      <path
        d="M12 0C5.4 0 0 5.4 0 12C0 17.1 3.2 21.4 7.6 23.2C7.5 22.3 7.4 20.8 7.6 19.8C7.8 18.9 9 13.8 9 13.8C9 13.8 8.7 13 8.7 12C8.7 10.3 9.7 9.1 10.9 9.1C11.9 9.1 12.4 9.9 12.4 10.8C12.4 11.8 11.7 13.4 11.4 14.8C11.1 16 12 17 13.2 17C15.3 17 17 14.8 17 11.5C17 8.6 14.9 6.6 12 6.6C8.6 6.6 6.6 9.2 6.6 11.8C6.6 12.8 7 13.9 7.5 14.5C7.6 14.6 7.6 14.7 7.6 14.8C7.5 15.2 7.3 16 7.3 16.2C7.2 16.4 7.1 16.5 6.9 16.4C5.4 15.7 4.5 13.5 4.5 11.8C4.5 8 7.3 4.5 12.4 4.5C16.6 4.5 19.8 7.5 19.8 11.4C19.8 15.5 17.2 18.9 13.6 18.9C12.4 18.9 11.2 18.3 10.8 17.5C10.8 17.5 10.2 19.8 10.1 20.4C9.8 21.4 9.1 22.7 8.6 23.5C9.6 23.8 10.8 24 12 24C18.6 24 24 18.6 24 12C24 5.4 18.6 0 12 0Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_3155_47228">
        <rect width="24" height="24" fill="currentColor" />
      </clipPath>
    </defs>
  </StyledIcon>
)
