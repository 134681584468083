import { useRouter } from 'next/router'
import { useEffect } from 'react'

export const ACTIVE_PROMO_STORE = '@fitify/activePromo'

export const useInitializePromo = () => {
  const router = useRouter()

  useEffect(() => {
    const queryPromo = router.query.promo as string | null
    const storedPromo =
      typeof window !== 'undefined' &&
      window.localStorage.getItem(ACTIVE_PROMO_STORE)

    const promo = queryPromo || storedPromo || ''

    if (promo) {
      window.localStorage.setItem(ACTIVE_PROMO_STORE, promo)
    }
  }, [router.query.promo])
}
