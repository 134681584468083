import { StyledIcon } from '../Icon.Styled'

export const ClockAlarmIcon = (props: any) => (
  <StyledIcon
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2604_41222)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8327 5.5V9.66667H14.9993V11.3333H9.16602V5.5H10.8327Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.77732 16.6875L3.16732 20.1675L1.83398 19.1675L4.44398 15.6875L5.77732 16.6875Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99935 3.83073C6.31745 3.83073 3.33268 6.8155 3.33268 10.4974C3.33268 14.1793 6.31745 17.1641 9.99935 17.1641C13.6812 17.1641 16.666 14.1793 16.666 10.4974C16.666 6.8155 13.6812 3.83073 9.99935 3.83073ZM1.66602 10.4974C1.66602 5.89502 5.39698 2.16406 9.99935 2.16406C14.6017 2.16406 18.3327 5.89502 18.3327 10.4974C18.3327 15.0998 14.6017 18.8307 9.99935 18.8307C5.39698 18.8307 1.66602 15.0998 1.66602 10.4974Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.58932 1.9207L1.42265 6.08737L0.244141 4.90885L4.41081 0.742188L5.58932 1.9207Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8327 20.1675L14.2227 16.6875L15.556 15.6875L18.166 19.1675L16.8327 20.1675Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5887 0.742188L19.7553 4.90885L18.5768 6.08737L14.4102 1.9207L15.5887 0.742188Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_2604_41222">
        <rect
          width="20"
          height="20"
          fill="currentColor"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </StyledIcon>
)
