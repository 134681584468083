import { StyledIcon } from '../Icon.Styled'

export const ThumbsDownIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M0 13h2.677c2.515 0 2.68.004 2.7.07.012.038.677 2.419 1.478 5.29.8 2.871 1.482 5.314 1.516 5.43l.061.21h.5c.593-.001 1.166-.079 1.573-.215a4.167 4.167 0 0 0 1.585-.968c.446-.436.784-.966.99-1.549.203-.582.22-.839.22-3.656v-2.609l3.611-.014c3.293-.012 3.638-.02 3.918-.085a4.17 4.17 0 0 0 1.989-1.07c.548-.53.933-1.2 1.112-1.934.053-.205.071-.447.07-.9-.001-.596-.024-.759-.565-4.16-.309-1.947-.589-3.652-.622-3.789C22.551 1.945 21.73.943 20.645.41a4.501 4.501 0 0 0-1.222-.367C19.202.013 16.182 0 9.553 0H0v13Zm5.115-2H2.046V2h3.07v9Zm14.219-8.939c.7.178 1.257.691 1.456 1.339.062.201.973 5.83 1.188 7.345.144 1.004-.576 2.004-1.59 2.211-.15.031-1.579.044-4.676.044h-4.458v3.632c0 3.949.006 3.843-.23 4.298-.14.269-.59.709-.864.844l-.213.106-.046-.17c-.025-.094-.652-2.346-1.393-5.005L7.16 11.871V2h5.967c5.225 0 5.995.008 6.206.061Z"
      clipRule="evenodd"
    />
  </StyledIcon>
)
