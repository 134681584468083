import { ImgHTMLAttributes } from 'react'

const NativeImage = ({
  alt,
  src,
  srcSet,
  width,
  height,
  loading,
  style,
  sizes,
}: ImgHTMLAttributes<HTMLImageElement>) => {
  return (
    <picture>
      <img
        alt={alt}
        src={src}
        srcSet={srcSet}
        width={width}
        height={height}
        loading={loading}
        style={style}
        sizes={sizes}
      />
    </picture>
  )
}

export default NativeImage
