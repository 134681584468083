import { StyledIcon } from '../Icon.Styled'

export const MetricsIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    {...props}
  >
    <g fill="currentColor" clipPath="url(#metrics)">
      <path d="M7.268 0H5.602V3.333H7.268C15.222 3.343 22.847 6.506 28.471 12.13 34.095 17.754 37.259 25.38 37.268 33.333V35H40.602V33.333C40.591 24.496 37.076 16.023 30.827 9.774 24.578 3.525 16.106.01 7.268 0V0ZM5.602 6.667H8.935V13.333H5.602V6.667Z" />
      <path d="M20.234 18.013 24.948 13.298 27.304 15.657 22.591 20.37 20.234 18.013ZM27.266 31.667H33.932V35H27.266V31.667ZM3.732 29.798C5.683 27.845 18.933 21.667 18.933 21.667 18.933 21.667 12.755 34.917 10.802 36.868 9.859 37.779 8.596 38.283 7.285 38.272 5.974 38.26 4.72 37.735 3.793 36.808 2.866 35.881 2.34 34.626 2.328 33.315 2.317 32.004 2.821 30.741 3.732 29.798Z" />
    </g>
    <defs>
      <clipPath id="metrics">
        <path fill="#fff" d="M0 0H40V40H0z" transform="translate(.602)" />
      </clipPath>
    </defs>
  </StyledIcon>
)
