import { StyledIcon } from '../Icon.Styled'

export const MicrophoneOutline = (props: any) => {
  return (
    <StyledIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.343 0.0454448C8.54104 0.314445 6.30604 2.58045 6.04104 5.42044C5.98804 5.99145 5.98804 10.0094 6.04104 10.5804C6.31304 13.4884 8.55504 15.7044 11.487 15.9644C12.2657 16.0371 13.0511 15.9553 13.7982 15.7239C14.5452 15.4924 15.2392 15.1158 15.8405 14.6157C16.4417 14.1155 16.9383 13.5016 17.3019 12.8092C17.6654 12.1167 17.8887 11.3594 17.959 10.5804C18.012 10.0094 18.012 5.99145 17.959 5.42044C17.8371 4.03484 17.2312 2.73641 16.2476 1.75286C15.2641 0.769302 13.9656 0.163413 12.58 0.0414448C12.1689 -0.00469843 11.7539 -0.00335626 11.343 0.0454448ZM12.696 2.06044C13.5074 2.19574 14.2561 2.58112 14.8378 3.16273C15.4194 3.74434 15.8047 4.49313 15.94 5.30444C16.028 5.81344 16.028 10.1874 15.94 10.6964C15.7828 11.6254 15.3 12.4681 14.578 13.0735C13.8561 13.6789 12.9422 14.0075 12 14.0004C11.0579 14.0075 10.144 13.6789 9.42205 13.0735C8.70011 12.4681 8.21728 11.6254 8.06004 10.6964C7.97204 10.1874 7.97204 5.81344 8.06004 5.30444C8.14908 4.78591 8.34 4.29015 8.62177 3.84583C8.90353 3.40151 9.27056 3.01744 9.70163 2.7158C10.1327 2.41416 10.6193 2.20093 11.1332 2.08845C11.6472 1.97596 12.1784 1.96644 12.696 2.06044ZM2.01204 9.99044C2.04304 11.2974 2.15704 12.0414 2.48704 13.0724C2.95696 14.5678 3.78137 15.9274 4.89004 17.0354C6.01605 18.1829 7.40736 19.0354 8.94104 19.5174C9.43404 19.6754 10.184 19.8464 10.62 19.9004C10.73 19.9134 10.861 19.9334 10.91 19.9434L11 19.9624V22.0004H6.00004V24.0004H18V22.0004H13V19.9624L13.09 19.9444C13.139 19.9354 13.423 19.8884 13.72 19.8404C15.35 19.5754 17 18.8284 18.341 17.7484C18.8587 17.3044 19.3405 16.8203 19.782 16.3004C20.489 15.4144 21.129 14.2364 21.471 13.1944C21.84 12.0684 21.956 11.3494 21.988 9.99044L22.012 9.00044H20V9.72244C20 10.4824 19.958 11.0104 19.855 11.5404C19.5036 13.3638 18.5264 15.0071 17.0922 16.1866C15.658 17.3661 13.8569 18.0077 12 18.0004C8.90004 18.0004 6.10004 16.2404 4.77604 13.4604C4.22704 12.3064 4.00004 11.2124 4.00004 9.72244V9.00044H1.98804L2.01204 9.99044Z"
        fill="currentColor"
      />
    </StyledIcon>
  )
}
