import { StyledIcon } from '../Icon.Styled'

export const CrossBigIcon = (props: any) => {
  return (
    <StyledIcon
      viewBox="0 0 24 24"
      height="24"
      width="24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19 5 5 19M19 19 5 5"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </StyledIcon>
  )
}
