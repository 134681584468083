import { StyledIcon } from '../Icon.Styled'

export const CheckboxIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <rect width="15" height="15" x=".5" y=".5" stroke="currentColor" rx="3.5" />
  </StyledIcon>
)
