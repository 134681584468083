import { StyledIcon } from '../Icon.Styled'

export const RunningShoe = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="39"
    height="36"
    viewBox="0 0 39 36"
    fill="none"
    {...props}
  >
    <path
      fill="#007AFF"
      d="m25.248 7.357 4.327 13.31 6.308 4.666a5.237 5.237 0 0 1 1.644 7.225l-.93 1.479a3.5 3.5 0 0 1-4.817 1.096L3.297 16.508A3.5 3.5 0 0 1 2.2 11.69L8.867 1.093c.708-1.126 2.633-1.191 3 .087a9.565 9.565 0 0 0 5.111 5.848 12.5 12.5 0 0 0 8.27.329ZM20 32.333H0v3.334h20v-3.334ZM8.333 24H0v3.333h8.333V24Z"
    />
  </StyledIcon>
)
