import { StyledIcon } from '../Icon.Styled'

export const StatusCompletedIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="8"
    viewBox="0 0 8 8"
    {...props}
  >
    <path
      fill="currentColor"
      d="M.333.667v6.666a.333.333 0 0 0 .334.334h6.666a.333.333 0 0 0 .334-.334V.667a.333.333 0 0 0-.334-.334H.667a.333.333 0 0 0-.334.334Zm3.098 3.43 1.764-1.764H3.667v-.666h2.666v2.666h-.666V2.805L3.902 4.569l-.471-.471Z"
    />
  </StyledIcon>
)
