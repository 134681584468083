import { StyledIcon } from '../Icon.Styled'

export const LocalFireDepartmentIcon = (props: any) => {
  return (
    <StyledIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.988 8.23408C11.9413 5.51408 8.21464 5.36741 9.11464 1.41408C9.1813 1.12074 8.86797 0.894078 8.61464 1.04741C6.19464 2.47408 4.45464 5.33408 5.91464 9.08074C6.03464 9.38741 5.67464 9.67408 5.41464 9.47408C4.20797 8.56074 4.0813 7.24741 4.18797 6.30741C4.22797 5.96074 3.77464 5.79408 3.5813 6.08074C3.12797 6.77408 2.66797 7.89408 2.66797 9.58074C2.9213 13.3141 6.07464 14.4607 7.20797 14.6074C8.82797 14.8141 10.5813 14.5141 11.8413 13.3607C13.228 12.0741 13.7346 10.0207 12.988 8.23408ZM6.8013 11.5874C7.7613 11.3541 8.25464 10.6607 8.38797 10.0474C8.60797 9.09408 7.74797 8.16074 8.32797 6.65408C8.54797 7.90074 10.508 8.68075 10.508 10.0407C10.5613 11.7274 8.73464 13.1741 6.8013 11.5874Z"
        fill="currentColor"
      />
    </StyledIcon>
  )
}
