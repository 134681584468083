import { StyledIcon } from '../Icon.Styled'

export const ShareIcon = (props: any) => {
  return (
    <StyledIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_187_106)">
        <g clipPath="url(#clip1_187_106)">
          <mask
            id="mask0_187_106"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <path d="M0 0h24v24H0V0Z" fill="currentColor" />
          </mask>
          <g mask="url(#mask0_187_106)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="m17.442.75-.75.751 1.6 1.6 1.599 1.6-.85.027c-2.713.085-5.034.927-7.15 2.594-.394.312-1.482 1.4-1.803 1.806a11.94 11.94 0 0 0-2.572 6.825l-.03.547h2.142l.029-.52a9.747 9.747 0 0 1 2.137-5.555 9.593 9.593 0 0 1 3.226-2.563c1.363-.669 2.502-.948 4.05-.99l.835-.023-1.607 1.61-1.607 1.613.76.76.762.761 2.902-2.904 2.906-2.904-2.893-2.892C20.162 1.923 19.193.958 18.213 0c-.013 0-.36.337-.771.75ZM0 15.429V24h19.285v-9.642H17.14v7.498H2.142V9H7.5V6.857H0v8.57Z"
              fill="currentColor"
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_187_106">
          <path fill="currentColor" d="M0 0h24v24H0z" />
        </clipPath>
        <clipPath id="clip1_187_106">
          <path fill="currentColor" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </StyledIcon>
  )
}
