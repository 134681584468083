import { StyledIcon } from '../Icon.Styled'

export const FacebookIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    {...props}
    fill="none"
  >
    <path
      fill="currentColor"
      d="M24 12.069a12 12 0 1 0-13.875 11.854v-8.385H7.078v-3.47h3.047V9.426c0-3.007 1.792-4.67 4.532-4.67.9.014 1.798.092 2.687.235v2.954H15.83a1.734 1.734 0 0 0-1.955 1.875v2.25H17.2l-.532 3.469h-2.8v8.385A12 12 0 0 0 24 12.069Z"
    />
  </StyledIcon>
)
