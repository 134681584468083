import { StyledIcon } from '../Icon.Styled'

export const IntensityPerfectIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="24"
    viewBox="0 0 26 24"
    {...props}
  >
    <path
      d="M11.7873 14.0277L11.7873 14.0277C11.3209 15.9409 11.0638 17.1212 10.9768 17.7583L10.9768 17.7585C10.9047 18.291 10.9638 18.6755 11.1925 19.1085L11.1926 19.1087C11.2669 19.2486 11.3918 19.4036 11.5273 19.539C11.6627 19.6744 11.8176 19.7993 11.9574 19.8736C12.3423 20.0795 12.7804 20.1611 13.211 20.1068L13.2115 20.1067C13.4536 20.0752 13.6531 20.0239 13.8364 19.9347C14.0199 19.8455 14.1806 19.7215 14.3491 19.553C14.5347 19.3674 14.5994 19.287 14.682 19.129L14.6823 19.1285C14.9073 18.6943 14.9628 18.294 14.8803 17.7486C14.7422 16.828 14.26 14.7092 13.4535 11.4736L13.4534 11.4734C13.3312 10.9857 13.2101 10.4978 13.0903 10.0095C13.0853 9.9868 13.0781 9.96245 13.0683 9.94152C13.0644 9.93331 13.0544 9.91268 13.0356 9.89434C13.0265 9.8855 13.0035 9.86534 12.9668 9.85897C12.921 9.851 12.8804 9.86874 12.8545 9.89463C12.8427 9.90638 12.8361 9.9178 12.8344 9.9208L12.8344 9.92087C12.8317 9.92556 12.8298 9.92952 12.8288 9.93174C12.8267 9.93627 12.8252 9.94017 12.8244 9.94224C12.8228 9.94672 12.8213 9.95126 12.8201 9.95484C12.8177 9.96239 12.8149 9.97216 12.8117 9.98332C12.8052 10.006 12.7964 10.0382 12.7856 10.0785C12.7639 10.1594 12.7335 10.2752 12.6967 10.4171C12.6231 10.7012 12.5233 11.0914 12.4144 11.5205C12.1966 12.3788 11.9421 13.3936 11.7873 14.0277Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.25"
    />
    <path
      d="M22.2766 20.7197C22.8548 21.0672 23.6226 20.8866 23.9223 20.2649C24.681 18.691 25.0925 16.9674 25.1232 15.2116C25.1603 13.0836 24.6366 10.9832 23.6048 9.12168C22.5729 7.26016 21.0692 5.70304 19.2448 4.60685C17.4205 3.51065 15.3397 2.91399 13.2116 2.87685C11.0836 2.8397 8.98321 3.36338 7.12168 4.39524C5.26016 5.4271 3.70304 6.93078 2.60685 8.75516C1.51065 10.5795 0.913992 12.6603 0.876847 14.7884C0.8462 16.5442 1.19731 18.281 1.90062 19.8805C2.17844 20.5123 2.93951 20.7195 3.52947 20.3925C4.12512 20.0623 4.32393 19.3213 4.06943 18.7084C3.56103 17.4841 3.30829 16.1645 3.33157 14.8312C3.36119 13.1341 3.83703 11.4746 4.71127 10.0196C5.58551 8.56465 6.82734 7.36544 8.31193 6.54251C9.79654 5.71958 11.4716 5.30194 13.1688 5.33157C14.8659 5.36119 16.5254 5.83703 17.9804 6.71127C19.4353 7.58551 20.6346 8.82734 21.4575 10.3119C22.2804 11.7965 22.6981 13.4716 22.6684 15.1688C22.6452 16.502 22.3465 17.812 21.7957 19.0178C21.52 19.6214 21.6928 20.369 22.2766 20.7197Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.25"
    />
  </StyledIcon>
)
