import { StyledIcon } from '../Icon.Styled'

export const CircleWarningIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    {...props}
  >
    <g clipPath="url(#clip0_3200_47762)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.38286 0.0216169C8.3687 0.10245 7.55203 0.266617 6.71786 0.556617C5.05051 1.13791 3.56659 2.14991 2.41667 3.48994C1.26676 4.82997 0.491777 6.45034 0.170365 8.18662C0.0495312 8.83828 0.0195312 9.20412 0.0195312 9.99995C0.0195312 10.7958 0.0495312 11.1616 0.170365 11.8133C0.516032 13.6799 1.38543 15.4097 2.67712 16.8008C3.9688 18.1919 5.62948 19.187 7.46536 19.67C8.84244 20.0295 10.2804 20.091 11.6832 19.8503C13.0859 19.6096 14.4211 19.0722 15.5995 18.2741C17.1883 17.2006 18.4342 15.6922 19.1883 13.9293C19.9424 12.1665 20.1729 10.2237 19.852 8.33328C19.5534 6.55739 18.7799 4.89534 17.6136 3.52321C16.4473 2.15108 14.9316 1.11997 13.227 0.539117C12.052 0.13495 10.5312 -0.0700497 9.38286 0.0216169ZM11.1995 1.74995C12.0962 1.88995 12.8895 2.13912 13.6829 2.53162C15.0113 3.18253 16.1414 4.17688 16.9562 5.41162C17.4545 6.15912 17.9412 7.29745 18.1295 8.14995C18.2787 8.82995 18.3145 9.18745 18.3145 9.99995C18.3145 10.8125 18.2787 11.17 18.1295 11.85C17.8812 12.9758 17.237 14.3075 16.4979 15.2216C16.105 15.6787 15.6782 16.1054 15.2212 16.4983C14.307 17.2375 12.9754 17.8816 11.8495 18.13C11.1695 18.2791 10.812 18.315 9.99953 18.315C8.92453 18.315 8.32203 18.2208 7.34953 17.8983C5.61286 17.3225 4.08286 16.1475 3.0437 14.5908C2.5487 13.8508 2.05786 12.7033 1.86953 11.85C1.72036 11.17 1.68453 10.8125 1.68453 9.99995C1.68453 8.92495 1.7787 8.32245 2.1012 7.34995C2.59036 5.87495 3.54203 4.50662 4.77703 3.50328C5.91786 2.57662 7.50536 1.90245 8.93953 1.73578L9.34953 1.68578C9.5812 1.65412 10.8679 1.69828 11.1995 1.74995ZM9.1662 8.33328V11.6666H10.8329V4.99995H9.1662V8.33328ZM9.72203 13.3875C9.4187 13.4808 9.1662 13.835 9.1662 14.1666C9.1662 14.5975 9.5687 15 9.99953 15C10.4304 15 10.8329 14.5975 10.8329 14.1666C10.8329 13.8316 10.5729 13.4775 10.2579 13.3841C10.0844 13.3223 9.89471 13.3235 9.72203 13.3875Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_3200_47762">
        <rect width="20" height="20" fill="currentColor" />
      </clipPath>
    </defs>
  </StyledIcon>
)
