import { StyledIcon } from '../Icon.Styled'

export const StopwatchOutlineIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="10"
    viewBox="0 0 10 10"
    {...props}
  >
    <path
      d="M6.25 0.416667H3.75V1.25H6.25V0.416667ZM4.58333 5.83333H5.41667V3.33333H4.58333V5.83333ZM7.92917 3.07917L8.52083 2.4875C8.34167 2.275 8.14583 2.075 7.93333 1.9L7.34167 2.49167C6.69583 1.975 5.88333 1.66667 5 1.66667C2.92917 1.66667 1.25 3.34583 1.25 5.41667C1.25 7.4875 2.925 9.16667 5 9.16667C7.075 9.16667 8.75 7.4875 8.75 5.41667C8.75 4.53333 8.44167 3.72083 7.92917 3.07917ZM5 8.33333C3.3875 8.33333 2.08333 7.02917 2.08333 5.41667C2.08333 3.80417 3.3875 2.5 5 2.5C6.6125 2.5 7.91667 3.80417 7.91667 5.41667C7.91667 7.02917 6.6125 8.33333 5 8.33333Z"
      fill="currentColor"
    />
  </StyledIcon>
)
