import { StyledIcon } from '../Icon.Styled'

export const TooltipIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M5 3h1v1H5V3Zm0 2h1v3H5V5ZM5.5.5c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5Zm0 9c-2.205 0-4-1.795-4-4s1.795-4 4-4 4 1.795 4 4-1.795 4-4 4Z"
    />
  </StyledIcon>
)
