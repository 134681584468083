import { StyledIcon } from '../Icon.Styled'

export const FitifyLogoIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="498"
    height="87"
    viewBox="0 0 498 87"
    fill="none"
    {...props}
  >
    <path
      d="M 0,0 H 76.422 V 12.521 H 17.7125 V 38.269 H 67.577 V 50.79 H 17.7125 v 26.411 c 0,2.343 -0.9319,4.59 -2.5907,6.246 -1.6588,1.657 -3.9086,2.587 -6.2545,2.587 -2.3459,0 -4.5957,-0.93 -6.2545,-2.587 C 0.954,81.791 0.0221,79.544 0.0221,77.201 Z"
      fill="currentColor"
    />
    <path
      d="m 102.96,0 h 17.834 v 77.146 c 0,1.17 -0.231,2.328 -0.679,3.408 -0.449,1.081 -1.106,2.062 -1.935,2.889 -0.829,0.826 -1.812,1.482 -2.895,1.928 -1.082,0.447 -2.242,0.676 -3.414,0.675 v 0 c -2.363,0 -4.63,-0.938 -6.301,-2.607 -1.671,-1.669 -2.61,-3.932 -2.61,-6.293 z"
      fill="currentColor"
    />
    <path
      d="M 180.242,12.631 H 146.575 V 0 h 85.201 v 12.631 h -33.744 v 64.515 c 0,1.172 -0.232,2.332 -0.681,3.414 -0.45,1.082 -1.11,2.064 -1.941,2.891 -0.831,0.827 -1.817,1.482 -2.902,1.927 -1.085,0.445 -2.248,0.672 -3.421,0.668 v 0 c -1.171,10e-4 -2.331,-0.228 -3.413,-0.675 -1.083,-0.446 -2.066,-1.102 -2.895,-1.928 -0.829,-0.827 -1.486,-1.808 -1.935,-2.889 -0.448,-1.08 -0.679,-2.238 -0.679,-3.408 z"
      fill="currentColor"
    />
    <path
      d="m 257.4,0 h 17.835 v 77.146 c -0.003,2.362 -0.945,4.625 -2.618,6.294 -1.673,1.668 -3.941,2.606 -6.305,2.606 -2.364,0 -4.63,-0.938 -6.301,-2.607 -1.672,-1.669 -2.611,-3.932 -2.611,-6.293 z"
      fill="currentColor"
    />
    <path
      d="m 311.025,0 h 76.401 v 12.521 h -58.688 v 25.748 h 49.887 V 50.79 h -49.887 v 26.411 c 0,2.343 -0.932,4.59 -2.591,6.246 -1.659,1.657 -3.908,2.587 -6.254,2.587 -2.346,0 -4.596,-0.93 -6.255,-2.587 -1.659,-1.656 -2.59,-3.903 -2.59,-6.246 z"
      fill="currentColor"
    />
    <path
      d="M 441.203,47.279 403.976,0 h 19.769 L 452.779,36.823 480.42,0 h 17.536 l -36.995,49.686 v 27.449 c 0,1.17 -0.231,2.328 -0.68,3.408 -0.448,1.081 -1.106,2.062 -1.934,2.889 -0.829,0.826 -1.813,1.482 -2.895,1.928 -1.083,0.447 -2.243,0.676 -3.414,0.674 v 0 c -2.363,0 -4.63,-0.937 -6.301,-2.606 -1.671,-1.669 -2.61,-3.932 -2.61,-6.293 V 52.778 c -0.006,-1.997 -0.683,-3.933 -1.924,-5.499 z"
      fill="currentColor"
    />
  </StyledIcon>
)
