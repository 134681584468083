import { StyledIcon } from '../Icon.Styled'

export const StandIcon = (props: any) => (
  <StyledIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12 6C13.657 6 15 4.657 15 3 15 1.343 13.657 0 12 0 10.343 0 9 1.343 9 3 9 4.657 10.343 6 12 6ZM16 7H8C6.346 7 5 8.346 5 10V15C5 15.553 5.447 16 6 16 6.553 16 7 15.553 7 15V10C7 9.448 7.448 9 8 9V23C8 23.553 8.447 24 9 24 9.553 24 10 23.553 10 23V18H14V23C14 23.553 14.447 24 15 24 15.553 24 16 23.553 16 23V9C16.552 9 17 9.448 17 10V15C17 15.553 17.447 16 18 16 18.553 16 19 15.553 19 15V10C19 8.346 17.654 7 16 7Z"
    />
  </StyledIcon>
)
