import { StyledIcon } from '../Icon.Styled'

export const CheckboxCheckedIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <rect width="16" height="16" fill="currentColor" rx="4" />
    <path
      fill="#fff"
      d="M6.542 11.333a.625.625 0 0 1-.442-.183L3.158 8.208l.884-.883 2.5 2.5 5.416-5.417.884.884-5.858 5.858a.626.626 0 0 1-.442.183Z"
    />
  </StyledIcon>
)
