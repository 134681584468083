import { StyledIcon } from '../Icon.Styled'

export const ThumbsUpIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M0 11h2.677c2.515 0 2.68-.004 2.7-.07.012-.038.677-2.419 1.478-5.29C7.654 2.769 8.336.326 8.37.21L8.432 0h.5c.593.001 1.166.079 1.573.215.596.2 1.138.532 1.585.968.446.436.784.966.99 1.549.203.582.22.839.22 3.656v2.609l3.611.014c3.293.012 3.638.02 3.918.085.752.169 1.44.54 1.989 1.07.548.53.933 1.2 1.112 1.934.053.205.071.447.07.9-.001.596-.024.759-.565 4.16-.309 1.947-.59 3.652-.622 3.789-.262 1.106-1.084 2.108-2.168 2.641a4.5 4.5 0 0 1-1.222.367c-.221.03-3.241.043-9.87.043H0V11Zm5.115 2H2.046v9h3.07v-9Zm14.219 8.939c.7-.178 1.257-.691 1.456-1.339.062-.201.973-5.83 1.188-7.345.144-1.004-.576-2.004-1.59-2.211-.15-.031-1.579-.044-4.676-.044h-4.458V7.368c0-3.949.006-3.843-.23-4.298-.14-.269-.59-.709-.864-.844l-.213-.106-.046.17c-.025.094-.652 2.346-1.393 5.005L7.16 12.129V22h5.967c5.225 0 5.995-.008 6.206-.061Z"
      clipRule="evenodd"
    />
  </StyledIcon>
)
