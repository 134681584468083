import { StyledIcon } from '../Icon.Styled'

export const StravaLogoRoundedIcon = (props: any) => {
  return (
    <StyledIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="16" height="16" rx="4" ry="4" fill="#FC6B26" />
      <g fill="#fff" fillRule="evenodd">
        <path d="M6.9 8.8l2.5 4.5 2.4-4.5h-1.5l-.9 1.7-1-1.7z" opacity=".6" />
        <path d="M7.2 2.5l3.1 6.3H4zm0 3.8l1.2 2.5H5.9z" />
      </g>
    </StyledIcon>
  )
}
