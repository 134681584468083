import { StyledIcon } from '../Icon.Styled'

export const BosuToolIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20.789 9.972a10.3 10.3 0 0 0-1.564-1.707c-1.032.838-3.778 1.36-7.335 1.36-3.43 0-6.13-.486-7.237-1.284-.55.49-1.047 1.038-1.48 1.636.963.838 3.967 1.639 8.805 1.639 4.85 0 7.853-.803 8.81-1.644Zm.364.537c-1.453 1.026-4.915 1.668-9.175 1.668-4.32 0-7.718-.627-9.17-1.661-.22.347-.42.71-.595 1.086l-.064.135c1.004 1.09 4.094 2.29 9.79 2.29 5.474 0 8.827-1.154 9.847-2.353l-.034-.072a9.948 9.948 0 0 0-.599-1.093ZM1.865 13.023c.007-.115.032-.344.083-.602.686.562 1.746 1.045 3.116 1.415 1.872.505 4.25.772 6.876.772 4.674 0 8.459-.864 10.065-2.248.068.324.09.6.095.664-.013.749-.987 1.53-2.677 2.144-1.966.714-4.608 1.107-7.44 1.107-2.833 0-5.475-.393-7.44-1.107-1.692-.615-2.667-1.396-2.678-2.145Zm12.806-7.114a10.953 10.953 0 0 1 3.977 1.887c-.66.604-3.097 1.19-6.758 1.19-3.628 0-5.903-.543-6.668-1.118a10.945 10.945 0 0 1 4.07-1.959c.448.38 1.434.598 2.708.598 1.25 0 2.228-.211 2.686-.579l-.015-.02ZM1.587 11.384C3.35 7.624 7.341 5 11.982 5c4.642 0 8.633 2.624 10.395 6.384h.001c.035.067.067.138.096.21.257.642.3 1.415.3 1.415 0 .243-.063.48-.183.71.479-.49.741-1.02.741-1.575 0 0-.007-.137-.032-.347.16.08.664.386.664 1.056v1.611s-.008.511-.216.888c-.556.822-1.626 1.557-3.06 2.148-2.214.91-5.296 1.476-8.706 1.476-3.41 0-6.492-.565-8.705-1.476-1.435-.59-2.504-1.326-3.06-2.148-.208-.377-.217-.888-.217-.888v-1.611c0-.67.504-.976.664-1.056a5.88 5.88 0 0 0-.032.347c0 .555.263 1.086.742 1.576a1.52 1.52 0 0 1-.184-.71s.044-.774.301-1.415c.03-.073.061-.144.096-.211Z"
      clipRule="evenodd"
    />
  </StyledIcon>
)
