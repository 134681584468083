import { StyledIcon } from '../Icon.Styled'

export const JumpRopeIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
    {...props}
    fill="none"
  >
    <path
      fill="#95EB00"
      d="M24 12a6 6 0 1 0 0-12 6 6 0 0 0 0 12ZM44.472 18.626l-15.922-4.55A2.037 2.037 0 0 0 28 14h-8c-.186 0-.37.026-.55.076l-15.922 4.55 1.098 3.846L18 18.652V38h4v-6h4v16h4V18.652l13.374 3.82 1.098-3.846Z"
    />
    <path
      fill="#95EB00"
      d="M22 43.862A16.016 16.016 0 0 1 8 28v-2H4v2a20.026 20.026 0 0 0 18 19.898v-4.036ZM40 26v2a15.967 15.967 0 0 1-6 12.47v4.824A20 20 0 0 0 44 28v-2h-4Z"
    />
  </StyledIcon>
)
