import { StyledIcon } from '../Icon.Styled'

export const RemoveIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="8"
    fill="none"
    viewBox="0 0 8 8"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#FF3D00"
        d="M4 0a4 4 0 1 0 4 4 4.012 4.012 0 0 0-4-4Zm1.65 5.179-.471.471L4 4.471 2.821 5.65l-.471-.471L3.529 4 2.35 2.821l.471-.471L4 3.529 5.179 2.35l.471.471L4.471 4 5.65 5.179Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h8v8H0z" />
      </clipPath>
    </defs>
  </StyledIcon>
)
