import { StyledIcon } from '../Icon.Styled'

export const UserPinIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M38.3333 1.66663H1.66667C0.746667 1.66663 0 2.41329 0 3.33329V31.6666C0 32.5866 0.746667 33.3333 1.66667 33.3333H13.6633L18.72 39.4C19.0367 39.78 19.505 40 20 40C20.495 40 20.9633 39.78 21.28 39.4L26.3367 33.3333H38.3333C39.2533 33.3333 40 32.5866 40 31.6666V3.33329C40 2.41329 39.2533 1.66663 38.3333 1.66663ZM20 8.33329C22.3017 8.33329 24.1667 10.1983 24.1667 12.5C24.1667 14.8016 22.3017 16.6666 20 16.6666C17.6983 16.6666 15.8333 14.8016 15.8333 12.5C15.8333 10.1983 17.6983 8.33329 20 8.33329ZM27.5 26.6666H12.5V23.965C12.5 22.48 13.475 21.1816 14.9 20.7616C16.225 20.37 18.005 20 20 20C21.995 20 23.775 20.37 25.1 20.7616C26.525 21.1816 27.5 22.48 27.5 23.965V26.6666Z"
    />
  </StyledIcon>
)
