import { StyledIcon } from '../Icon.Styled'

export const PencilFillIcon = (props: any) => {
  return (
    <StyledIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.915 17.3291L1.02 21.8041C0.954005 22.1321 1.057 22.4711 1.293 22.7071C1.482 22.8961 1.737 23.0001 2 23.0001C2.065 23.0001 2.13101 22.9941 2.196 22.9801L6.671 22.0851L1.915 17.3291Z"
        fill="currentColor"
      />
      <path
        d="M13.586 5L11 7.586L3 15.586L8.414 21L19 10.414L13.586 5Z"
        fill="currentColor"
      />
      <path
        d="M22.707 5.29325L18.707 1.29325C18.316 0.90225 17.684 0.90225 17.293 1.29325L15 3.58625L20.414 9.00025L22.707 6.70725C23.098 6.31625 23.098 5.68425 22.707 5.29325Z"
        fill="currentColor"
      />
    </StyledIcon>
  )
}
