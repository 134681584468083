import { StyledIcon } from '../Icon.Styled'

export const StatusOkIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    {...props}
  >
    <path
      fill="#80DB00"
      d="M6 0a6 6 0 1 0 6 6 6.017 6.017 0 0 0-6-6ZM5 8.707 2.293 6 3 5.293l2 2 4-4L9.707 4 5 8.707Z"
    />
  </StyledIcon>
)
