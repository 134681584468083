import { StyledIcon } from '../Icon.Styled'

export const PlusIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.688 2.437a.813.813 0 0 1 1.625 0v3.25h3.25a.813.813 0 0 1 0 1.626h-3.25v3.25a.812.812 0 1 1-1.625 0v-3.25h-3.25a.812.812 0 1 1 0-1.625h3.25v-3.25Z"
      fill="currentColor"
    />
  </StyledIcon>
)
