import { StyledIcon } from '../Icon.Styled'

export const IntensityTooEasyIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="24"
    viewBox="0 0 26 24"
    {...props}
  >
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth=".25"
      d="M9.73884 18.1505L9.73888 18.1505C11.5206 18.9326 12.6251 19.3853 13.2302 19.5793L13.2304 19.5794C13.7364 19.7409 14.1211 19.7494 14.5819 19.6011L14.5822 19.601C14.7313 19.5526 14.9037 19.4576 15.0588 19.349C15.2138 19.2404 15.362 19.111 15.4584 18.9876C15.725 18.6482 15.8797 18.2355 15.9008 17.8068L15.9008 17.8063C15.9117 17.5652 15.896 17.3621 15.8406 17.1682C15.7852 16.9742 15.692 16.7964 15.5569 16.6034C15.4081 16.3909 15.3409 16.3141 15.2011 16.2063L15.2007 16.206C14.8165 15.9124 14.4361 15.7895 13.891 15.7762C12.9714 15.7527 10.8268 15.8584 7.53985 16.088L7.53969 16.088C7.04414 16.1233 6.54851 16.1574 6.05282 16.1902C6.02983 16.1912 6.00479 16.194 5.9826 16.2C5.97392 16.2024 5.9519 16.2086 5.9306 16.224C5.92033 16.2314 5.89649 16.2505 5.88386 16.2855C5.86805 16.3292 5.87847 16.3723 5.89946 16.4023C5.90899 16.4159 5.91909 16.4244 5.92175 16.4266L5.9218 16.4267C5.92595 16.4301 5.92952 16.4327 5.93153 16.4341C5.93562 16.4369 5.9392 16.439 5.9411 16.4402C5.9452 16.4426 5.94939 16.4448 5.95269 16.4465C5.95965 16.4502 5.96867 16.4546 5.97899 16.4596C5.99992 16.4698 6.02976 16.4839 6.06718 16.5014C6.14218 16.5364 6.2496 16.5858 6.38144 16.646C6.64522 16.7664 7.00781 16.9304 7.40672 17.1101C8.20449 17.4693 9.14835 17.891 9.73884 18.1505Z"
    />
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth=".25"
      d="M22.2766 20.6895C22.8547 21.0362 23.6225 20.8562 23.9223 20.2354C24.681 18.6641 25.0925 16.9434 25.1232 15.1906C25.1603 13.0661 24.6366 10.9693 23.6047 9.11097C22.5728 7.2526 21.0691 5.69816 19.2448 4.60385C17.4204 3.50954 15.3396 2.91392 13.2116 2.87684C11.0836 2.83976 8.98328 3.36253 7.12176 4.3926C5.26025 5.42268 3.70312 6.92379 2.6069 8.74507C1.51067 10.5664 0.913994 12.6436 0.876847 14.7681C0.846199 16.5209 1.19732 18.2549 1.90065 19.8516C2.17854 20.4825 2.93958 20.6892 3.52939 20.3628C4.12505 20.0332 4.324 19.2933 4.0694 18.6812C3.56102 17.459 3.3083 16.1418 3.33157 14.8109C3.36119 13.1167 3.83701 11.4602 4.71122 10.0077C5.58544 8.55532 6.82724 7.35817 8.31186 6.53665C9.79647 5.71513 11.4716 5.2982 13.1688 5.32777C14.866 5.35734 16.5255 5.83238 17.9804 6.70512C19.4354 7.57786 20.6346 8.81754 21.4575 10.2995C22.2804 11.7815 22.6981 13.4537 22.6684 15.1478C22.6452 16.4787 22.3465 17.7864 21.7957 18.9901C21.5199 19.5929 21.6929 20.3393 22.2766 20.6895Z"
    />
  </StyledIcon>
)
