import { phone } from 'fitify-ui-landing/src/theme/breakpoints'
import styled, { css } from 'styled-components'

export const StyledContainer = styled.div`
  max-width: 1064px;
  margin: 0 auto;
  width: 100%;
  padding: 0 40px;

  ${phone(css`
    padding: 0 24px;
  `)};
`
