import { StyledIcon } from '../Icon.Styled'

export const HeartRateVariabilityIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <g fill="currentColor" clipPath="url(#a)">
      <path d="m6.434 11 2.71-4.515A.985.985 0 0 1 10.08 6a1 1 0 0 1 .85.625l3.25 8.13 1.963-3.273A1.002 1.002 0 0 1 17 11h6.34c.407-.949.632-1.967.66-3a6.956 6.956 0 0 0-7-7 7.12 7.12 0 0 0-5 2.1A7.119 7.119 0 0 0 7 1a6.957 6.957 0 0 0-7 7 8.16 8.16 0 0 0 .661 3h5.773Z" />
      <path d="m17.566 13-2.709 4.515A1 1 0 0 1 14 18h-.08a1 1 0 0 1-.849-.625l-3.25-8.132-1.964 3.272A1 1 0 0 1 7 13H1.648c3.065 5.29 9.374 10.5 9.752 10.8a.736.736 0 0 0 .6.2c.213-.02.418-.089.6-.2.378-.3 6.687-5.51 9.752-10.8h-4.786Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </StyledIcon>
)
