import { StyledIcon } from '../Icon.Styled'

export const MessageWithNotificationIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="12"
    viewBox="0 0 13 12"
    {...props}
  >
    <path
      fill="#fff"
      d="m.504 3.9-.003 6.892.945-.946.147-.146H8.1c.219 0 .4-.181.4-.4V3.9c0-.219-.181-.4-.4-.4H.9a.399.399 0 0 0-.396.4Z"
      stroke="#FF3D00"
    />
    <circle
      cx="9.5"
      cy="3.5"
      r="2.75"
      fill="#2979FF"
      stroke="#fff"
      strokeWidth=".5"
    />
  </StyledIcon>
)
