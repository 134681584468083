import { StyledIcon } from '../Icon.Styled'

export const StatusCrossIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    {...props}
  >
    <path
      fill="#FF3D00"
      d="M6 0a6 6 0 1 0 6 6 6.017 6.017 0 0 0-6-6Zm2.475 7.768-.707.707L6 6.707 4.232 8.475l-.707-.707L5.293 6 3.525 4.232l.707-.707L6 5.293l1.768-1.768.707.707L6.707 6l1.768 1.768Z"
    />
  </StyledIcon>
)
