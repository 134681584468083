import { StyledIcon } from '../Icon.Styled'

export const AddIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_13906_193004)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.56849 0.0151929C5.85858 0.0717762 5.28691 0.186693 4.70299 0.389693C3.53584 0.796597 2.4971 1.505 1.69216 2.44302C0.88722 3.38104 0.344732 4.5153 0.119743 5.73069C0.0351602 6.18686 0.0141602 6.44294 0.0141602 7.00003C0.0141602 7.55711 0.0351602 7.81319 0.119743 8.26936C0.36171 9.57598 0.970293 10.7868 1.87447 11.7606C2.77865 12.7344 3.94112 13.431 5.22624 13.769C6.1902 14.0207 7.19678 14.0638 8.1787 13.8953C9.16063 13.7268 10.0953 13.3506 10.9202 12.7919C12.0323 12.0405 12.9044 10.9846 13.4323 9.7506C13.9602 8.51658 14.1215 7.15663 13.8969 5.83336C13.6879 4.59023 13.1465 3.4268 12.33 2.46631C11.5136 1.50582 10.4526 0.784041 9.25941 0.377443C8.43691 0.0945262 7.37233 -0.0489738 6.56849 0.0151929ZM7.84016 1.22503C8.46783 1.32303 9.02316 1.49744 9.57849 1.77219C10.5084 2.22783 11.2995 2.92388 11.8698 3.78819C12.2187 4.31144 12.5593 5.10828 12.6912 5.70503C12.7956 6.18103 12.8207 6.43128 12.8207 7.00003C12.8207 7.56878 12.7956 7.81903 12.6912 8.29503C12.5173 9.08311 12.0664 10.0153 11.549 10.6552C11.274 10.9751 10.9753 11.2739 10.6553 11.5489C10.0154 12.0663 9.08324 12.5172 8.29516 12.691C7.81916 12.7954 7.56891 12.8205 7.00016 12.8205C6.24766 12.8205 5.82591 12.7546 5.14516 12.5289C3.92949 12.1258 2.85849 11.3033 2.13108 10.2136C1.78458 9.69561 1.44099 8.89236 1.30916 8.29503C1.20474 7.81903 1.17966 7.56878 1.17966 7.00003C1.17966 6.24753 1.24558 5.82578 1.47133 5.14503C1.81374 4.11253 2.47991 3.15469 3.34441 2.45236C4.14299 1.80369 5.25424 1.33178 6.25816 1.21511L6.54516 1.18011C6.70733 1.15794 7.60799 1.18886 7.84016 1.22503ZM6.41683 4.95836V6.41669H3.50016V7.58336H6.41683V10.5H7.58349V7.58336H10.5002V6.41669H7.58349V3.50003H6.41683V4.95836Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_13906_193004">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </StyledIcon>
)
