import { StyledIcon } from '../Icon.Styled'

export const AppleIcon = (props: any) => (
  <StyledIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="currentColor"
      d="M15 1V3C15 3.796 14.684 4.559 14.121 5.121 13.559 5.684 12.796 6 12 6V4C12 3.204 12.316 2.441 12.879 1.879 13.441 1.316 14.204 1 15 1V1ZM20.033 8.013C17.585 6.104 14.833 7.479 12.5 7.479 10.167 7.479 7.415 6.1 4.967 8.013 2.722 9.765 1.977 15.077 5.006 19.773 7.912 24.273 10.842 22.751 12.5 22.751 14.158 22.751 17.088 24.278 19.994 19.773 23.023 15.077 22.278 9.765 20.033 8.013Z"
    />
  </StyledIcon>
)
