import { StyledIcon } from '../Icon.Styled'

export const LungsIcon = (props: any) => {
  return (
    <StyledIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.275 4.038C16.1261 3.99542 15.9694 3.98799 15.8171 4.01631C15.6649 4.04462 15.5213 4.10791 15.3977 4.20118C15.2741 4.29444 15.1738 4.41514 15.1048 4.55377C15.0358 4.69239 14.9999 4.84515 15 5V12.946L13 12.28V0H11V12.28L8.99999 12.946V5C9.00007 4.84515 8.9642 4.69239 8.89518 4.55377C8.82617 4.41514 8.7259 4.29444 8.60229 4.20118C8.47867 4.10791 8.33509 4.04462 8.18285 4.01631C8.03061 3.98799 7.87387 3.99542 7.72499 4.038C5.43812 4.80486 3.4557 6.28157 2.06628 8.2532C0.676861 10.2248 -0.0470274 12.5884 -1.33622e-05 15V21.1C-0.0103514 21.5106 0.0659707 21.9187 0.223975 22.2977C0.381979 22.6768 0.618117 23.0183 0.916987 23.3C1.41847 23.7493 2.06768 23.9984 2.74099 24C2.99869 23.9995 3.2551 23.9635 3.50299 23.893L5.37499 23.357C6.41921 23.0583 7.33778 22.4278 7.9918 21.5607C8.64582 20.6936 8.99972 19.6371 8.99999 18.551V15.051L12 14.051L15 15.051V18.551C15 19.6373 15.3538 20.694 16.0079 21.5613C16.6619 22.4286 17.5806 23.0593 18.625 23.358L20.497 23.894C20.7449 23.9645 21.0013 24.0005 21.259 24.001C21.9323 23.9994 22.5815 23.7503 23.083 23.301C23.382 23.0192 23.6182 22.6775 23.7762 22.2983C23.9342 21.919 24.0105 21.5107 24 21.1V15C24.047 12.5884 23.3231 10.2248 21.9337 8.2532C20.5443 6.28157 18.5618 4.80486 16.275 4.038Z"
        fill="currentColor"
      />
    </StyledIcon>
  )
}
