import { StyledIcon } from '../Icon.Styled'

export const HeartOutlineIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="10"
    viewBox="0 0 10 10"
    {...props}
  >
    <path
      fill={'transparent'}
      d="m5.613 1.751.004-.005.004-.006A2.024 2.024 0 0 1 7.635.954a2.4 2.4 0 0 1 1.86 2.506l-.002.017v.017c0 .544-.235 1.159-.65 1.806-.41.64-.966 1.27-1.544 1.833A20.246 20.246 0 0 1 5 9.048a20.263 20.263 0 0 1-2.3-1.915c-.577-.564-1.134-1.194-1.544-1.834C.742 4.652.507 4.038.507 3.494v-.017L.506 3.46A2.4 2.4 0 0 1 2.365.954a2.024 2.024 0 0 1 2.014.786l.008.011a.777.777 0 0 0 1.226 0Zm-.487 7.385Z"
      stroke="currentColor"
    />
  </StyledIcon>
)
