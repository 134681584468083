import { StyledIcon } from '../Icon.Styled'

export const DumbbellToolIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.75 4a2.25 2.25 0 0 0-2.25 2.25v11.5a2.25 2.25 0 0 0 4.5 0V6.25A2.25 2.25 0 0 0 18.75 4ZM0 9.6A1.6 1.6 0 0 1 1.6 8c.22 0 .4.18.4.4v7.2a.4.4 0 0 1-.4.4A1.6 1.6 0 0 1 0 14.4V9.6Zm22-1.2c0-.22.18-.4.4-.4A1.6 1.6 0 0 1 24 9.6v4.8a1.6 1.6 0 0 1-1.6 1.6.4.4 0 0 1-.4-.4V8.4ZM3 6.25a2.25 2.25 0 0 1 4.5 0v11.5a2.25 2.25 0 0 1-4.5 0V6.25Zm6 4.25a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5h6a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5H9Z"
      clipRule="evenodd"
    />
  </StyledIcon>
)
