import { StyledIcon } from '../Icon.Styled'

export const TrashBinIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M7 2v2H0v2h24V4h-7V0H7v2Zm8 1v1H9V2h6v1ZM3.98 8.006c-.506.036-.948.07-.98.07-.056.004-.004.836.404 6.554.258 3.602.48 6.658.496 6.79.066.606.384 1.23.86 1.7.464.454.958.716 1.558.824.446.08 10.92.08 11.364 0 .6-.108 1.094-.368 1.558-.824a2.9 2.9 0 0 0 .86-1.7c.016-.132.238-3.188.494-6.79.408-5.718.46-6.55.408-6.552l-.992-.07c-.31-.03-.62-.046-.932-.048l-.472 6.64c-.38 5.3-.488 6.652-.54 6.78a1.06 1.06 0 0 1-.524.526l-.2.092H6.66l-.2-.092a1.06 1.06 0 0 1-.522-.526c-.054-.128-.162-1.48-.542-6.796-.26-3.65-.48-6.638-.486-6.642-.31.017-.62.038-.93.064Z"
    />
  </StyledIcon>
)
