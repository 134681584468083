import { StyledIcon } from '../Icon.Styled'

export const BalanceIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      d="M12 6C13.6569 6 15 4.65685 15 3C15 1.34315 13.6569 0 12 0C10.3431 0 9 1.34315 9 3C9 4.65685 10.3431 6 12 6Z"
      fill="currentColor"
    />
    <path
      d="M23 9V7H1V9H9V24H11V16H16.612L15.052 20.684L16.952 21.316L18.952 15.316C19.0022 15.1659 19.016 15.0061 18.9923 14.8496C18.9687 14.6932 18.9083 14.5446 18.816 14.416C18.7245 14.2851 18.6022 14.1786 18.4599 14.1061C18.3177 14.0336 18.1597 13.9971 18 14H15V9H23Z"
      fill="currentColor"
    />
  </StyledIcon>
)
