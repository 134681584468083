import { StyledIcon } from '../Icon.Styled'

export const StatusPlannedCompletedIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="8"
    viewBox="0 0 8 8"
    {...props}
  >
    <g clipPath="url(#statusPlannedCompleted)">
      <path
        fill="currentColor"
        d="M0.666707 7.66671H7.33337C7.42178 7.66671 7.50656 7.63159 7.56908 7.56908C7.63159 7.50656 7.66671 7.42178 7.66671 7.33337V0.666707C7.66671 0.578302 7.63159 0.493517 7.56908 0.431005C7.50656 0.368493 7.42178 0.333374 7.33337 0.333374H0.666707C0.578302 0.333374 0.493517 0.368493 0.431005 0.431005C0.368493 0.493517 0.333374 0.578302 0.333374 0.666707V7.33337C0.333374 7.42178 0.368493 7.50656 0.431005 7.56908C0.493517 7.63159 0.578302 7.66671 0.666707 7.66671ZM3.90237 3.43104L5.66671 5.19537V3.66671H6.33337V6.33337H3.66671V5.66671H5.19537L3.43104 3.90237L3.90237 3.43104Z"
      />
    </g>
    <defs>
      <clipPath id="statusPlannedCompleted">
        <path fill="#fff" d="M0 0H8V8H0z" />
      </clipPath>
    </defs>
  </StyledIcon>
)
