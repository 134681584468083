import { StyledIcon } from '../Icon.Styled'

export const HeartbeatIcon = (props: any) => {
  return (
    <StyledIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.434 11.0001L9.143 6.48513C9.23711 6.32376 9.37518 6.19247 9.54108 6.1066C9.70699 6.02072 9.8939 5.9838 10.08 6.00013C10.2657 6.0149 10.4435 6.08124 10.5935 6.19167C10.7436 6.30209 10.8597 6.45222 10.929 6.62513L14.18 14.7541L16.143 11.4821C16.2322 11.3348 16.358 11.213 16.5082 11.1285C16.6583 11.0441 16.8277 10.9999 17 11.0001H23.339C23.7472 10.051 23.9716 9.03296 24 8.00013C24.0057 7.07929 23.8285 6.16647 23.4788 5.31461C23.129 4.46275 22.6136 3.68881 21.9625 3.03766C21.3113 2.38651 20.5374 1.87111 19.6855 1.52135C18.8337 1.17159 17.9208 0.994424 17 1.00013C15.1215 1.01304 13.3243 1.76787 12 3.10013C10.6757 1.76787 8.87845 1.01304 7 1.00013C6.07915 0.994424 5.16633 1.17159 4.31447 1.52135C3.46261 1.87111 2.68867 2.38651 2.03752 3.03766C1.38637 3.68881 0.870971 4.46275 0.52121 5.31461C0.171449 6.16647 -0.00571301 7.07929 -3.60074e-06 8.00013C0.0284367 9.03296 0.252743 10.051 0.660996 11.0001H6.434Z"
        fill="currentColor"
      />
      <path
        d="M17.566 12.9992L14.857 17.5142C14.7681 17.6621 14.6425 17.7845 14.4923 17.8695C14.3422 17.9545 14.1725 17.9992 14 17.9992H13.92C13.7343 17.9844 13.5564 17.9181 13.4064 17.8077C13.2564 17.6972 13.1403 17.5471 13.071 17.3742L9.81999 9.24219L7.85699 12.5142C7.76813 12.6621 7.6425 12.7845 7.49233 12.8695C7.34216 12.9545 7.17255 12.9992 7 12.9992H1.64799C4.713 18.2882 11.022 23.4992 11.4 23.7992C11.4788 23.8751 11.5736 23.9324 11.6774 23.967C11.7812 24.0016 11.8914 24.0126 12 23.9992C12.2125 23.9784 12.4176 23.9101 12.6 23.7992C12.978 23.4992 19.287 18.2882 22.352 12.9992H17.566Z"
        fill="currentColor"
      />
    </StyledIcon>
  )
}
