import { StyledIcon } from '../Icon.Styled'

export const AiIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path fill="currentColor" d="M16 8H8v8h8V8Z" />
    <path
      fill="currentColor"
      d="M24 9V7h-3V5a2 2 0 0 0-2-2h-2V0h-2v3h-2V0h-2v3H9V0H7v3H5a2 2 0 0 0-2 2v2H0v2h3v2H0v2h3v2H0v2h3v2a2 2 0 0 0 2 2h2v3h2v-3h2v3h2v-3h2v3h2v-3h2a2 2 0 0 0 2-2v-2h3v-2h-3v-2h3v-2h-3V9h3Zm-6 9H6V6h12v12Z"
    />
  </StyledIcon>
)
