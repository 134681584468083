import { StyledIcon } from '../Icon.Styled'

export const CalendarEvent = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="39"
    viewBox="0 0 40 39"
    fill="none"
    {...props}
  >
    <path
      fill="#007AFF"
      d="M17.845 31.178a1.666 1.666 0 0 1-2.357 0l-5-5 2.357-2.356 3.822 3.821 10.488-10.488 2.357 2.357-11.667 11.666ZM31.667 5V1.667a1.667 1.667 0 1 0-3.334 0V5h3.334ZM11.667 5V1.667a1.667 1.667 0 1 0-3.334 0V5h3.334Z"
    />
    <path
      fill="#007AFF"
      d="M35 38.333H5a5 5 0 0 1-5-5V11.667a5 5 0 0 1 5-5h30a5 5 0 0 1 5 5v21.666a5 5 0 0 1-5 5ZM36.667 15H3.333v18.333A1.666 1.666 0 0 0 5 35h30a1.666 1.666 0 0 0 1.667-1.667V15Z"
    />
  </StyledIcon>
)
