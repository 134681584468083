import { StyledIcon } from '../Icon.Styled'

export const LatStationToolIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 40 40"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M28.333 26.667H11.667A1.666 1.666 0 0 0 10 28.333V30a1.667 1.667 0 0 0 1.667 1.667h6.666v5h-5V40h13.334v-3.333h-5v-5h6.666A1.666 1.666 0 0 0 30 30v-1.667a1.667 1.667 0 0 0-1.667-1.666ZM30 3.333h-8.333V0h-3.334v3.333H10a1.667 1.667 0 0 0-.745.167L1.097 7.588l1.491 2.982 7.805-3.903h7.94v16.666h3.334V6.667h7.94l7.805 3.903 1.491-2.982-8.158-4.08A1.667 1.667 0 0 0 30 3.333Z"
    />
  </StyledIcon>
)
