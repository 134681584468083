import { StyledIcon } from '../Icon.Styled'

export const IntensityBitHardIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="24"
    viewBox="0 0 26 24"
    {...props}
  >
    <path
      d="M14.0413 13.5237L14.0413 13.5237C12.8101 15.0606 12.0782 16.0216 11.7301 16.5623L11.73 16.5624C11.4397 17.0145 11.3308 17.388 11.355 17.8771L11.355 17.8774C11.3633 18.0355 11.411 18.2288 11.4765 18.4088C11.542 18.5887 11.6296 18.7673 11.7249 18.8938C11.9867 19.243 12.3493 19.5022 12.7625 19.6349L12.763 19.6351C12.9957 19.7088 13.1982 19.7466 13.402 19.7433C13.6061 19.7399 13.8041 19.6955 14.028 19.614C14.2747 19.5242 14.3672 19.4787 14.509 19.3704L14.5094 19.3701C14.8968 19.0716 15.1163 18.7323 15.272 18.2032C15.5359 17.3104 15.9943 15.1864 16.6308 11.9131L16.6308 11.9129C16.7262 11.4192 16.8227 10.9258 16.9204 10.4326C16.9254 10.4099 16.9292 10.3848 16.9292 10.3617C16.9291 10.3526 16.9288 10.3297 16.9195 10.3051C16.915 10.2933 16.9026 10.2653 16.8721 10.244C16.834 10.2174 16.7896 10.2163 16.7552 10.2289C16.7396 10.2345 16.7288 10.2421 16.726 10.2441L16.7259 10.2441C16.7215 10.2473 16.7181 10.25 16.7162 10.2516C16.7124 10.2549 16.7094 10.2578 16.7079 10.2593C16.7045 10.2627 16.7012 10.2662 16.6987 10.2689C16.6933 10.2748 16.6866 10.2824 16.679 10.2912C16.6635 10.3089 16.6419 10.3344 16.6151 10.3664C16.5612 10.4306 16.4848 10.5226 16.3914 10.6357C16.2046 10.8621 15.9493 11.1735 15.6692 11.5164C15.1091 12.2022 14.4496 13.0144 14.0413 13.5237Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.25"
    />
    <path
      d="M22.2766 19.7197C22.8548 20.0672 23.6226 19.8866 23.9223 19.2649C24.681 17.691 25.0925 15.9674 25.1232 14.2116C25.1603 12.0836 24.6366 9.98321 23.6048 8.12168C22.5729 6.26016 21.0692 4.70304 19.2448 3.60685C17.4205 2.51065 15.3397 1.91399 13.2116 1.87685C11.0836 1.8397 8.98321 2.36338 7.12168 3.39524C5.26016 4.4271 3.70304 5.93078 2.60685 7.75516C1.51065 9.57954 0.913992 11.6603 0.876847 13.7884C0.8462 15.5442 1.19731 17.281 1.90062 18.8805C2.17844 19.5123 2.93951 19.7195 3.52947 19.3925C4.12512 19.0623 4.32393 18.3213 4.06943 17.7084C3.56103 16.4841 3.30829 15.1645 3.33157 13.8312C3.36119 12.1341 3.83703 10.4746 4.71127 9.01963C5.58551 7.56465 6.82734 6.36544 8.31193 5.54251C9.79654 4.71958 11.4716 4.30194 13.1688 4.33157C14.8659 4.36119 16.5254 4.83703 17.9804 5.71127C19.4353 6.58551 20.6346 7.82734 21.4575 9.31194C22.2804 10.7965 22.6981 12.4716 22.6684 14.1688C22.6452 15.502 22.3465 16.812 21.7957 18.0178C21.52 18.6214 21.6928 19.369 22.2766 19.7197Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.25"
    />
  </StyledIcon>
)
