import { StyledIcon } from '../Icon.Styled'

export const CommentIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="10"
    viewBox="0 0 10 10"
    {...props}
  >
    <g clipPath="url(#comment)">
      <path
        fill="currentColor"
        d="M5 .417c-2.75 0-5 1.875-5 4.166C0 6.875 2.25 8.75 5 8.75a6.36 6.36 0 0 0 1.25-.125l2.333.916c.042 0 .084.042.167.042s.167-.042.25-.083c.125-.084.167-.25.167-.375l-.209-1.958C9.625 6.417 10 5.542 10 4.625 10 2.292 7.75.417 5 .417Z"
      />
    </g>
    <defs>
      <clipPath id="comment">
        <path fill="#fff" d="M0 0h10v10H0z" />
      </clipPath>
    </defs>
  </StyledIcon>
)
