import { StyledIcon } from '../Icon.Styled'

export const SandbagToolIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 40 40"
    fill="none"
    {...props}
  >
    <path fill="currentColor" d="M9 11h22v21H9V11Z" />
    <rect width="16" height="3" x="12" y="19" fill="#EFF7FF" rx="1.5" />
    <path
      fill="currentColor"
      d="M0 18a7 7 0 0 1 7-7v21a7 7 0 0 1-7-7v-7ZM40 18a7 7 0 0 0-7-7v21a7 7 0 0 0 7-7v-7Z"
    />
    <rect
      width="19"
      height="11"
      x="10.5"
      y="5.5"
      stroke="#007AFF"
      strokeWidth="3"
      rx="4.5"
    />
  </StyledIcon>
)
