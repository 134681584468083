import { StyledIcon } from '../Icon.Styled'

export const AddBigIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="11"
    viewBox="0 0 12 11"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.5 2.5V5h-5v1h5v5h1V6h5V5h-5V0h-1v2.5Z"
      clipRule="evenodd"
    />
  </StyledIcon>
)
