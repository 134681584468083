import { StyledIcon } from '../Icon.Styled'

export const ArrowFeedbackDownIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="9"
    height="9"
    viewBox="0 0 9 9"
    {...props}
  >
    <g clipPath="url(#arrowFeedbackDown)">
      <path
        fill="currentColor"
        d="M4.89681 8.0293L7.23014 4.69596H5.23014V0.36263C5.23014 0.178297 5.08081 0.0292969 4.89681 0.0292969C4.71281 0.0292969 4.56348 0.178297 4.56348 0.36263V4.69596H2.56348L4.89681 8.0293Z"
      />
    </g>
    <defs>
      <clipPath id="arrowFeedbackDown">
        <path fill="#fff" d="M0 0H8V8H0z" transform="translate(.896 .03)" />
      </clipPath>
    </defs>
  </StyledIcon>
)
