import { StyledIcon } from '../Icon.Styled'

export const LaunchAppIcon = (props: any) => {
  return (
    <StyledIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 12V23H23V14H21V21H3V3H10V1H1V12ZM13 2V3H19.58L14.94 7.64L10.3 12.28L11.01 12.99L11.72 13.7L16.36 9.06L21 4.42V11H23V1H13V2Z"
        fill="currentColor"
      />
    </StyledIcon>
  )
}
