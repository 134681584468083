import { StyledIcon } from '../Icon.Styled'

export const ArrowSmallDownIcon = (props: any) => (
  <StyledIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.27996 9.30002L6.57996 10L9.28996 12.71L12 15.42L14.71 12.71L17.42 10L16.71 9.29002L16 8.58002L14 10.58L12 12.58L10.01 10.59C9.34663 9.91964 8.67626 9.25627 7.99896 8.60002C7.75283 8.82662 7.51307 9.06005 7.27996 9.30002Z"
      fill="currentColor"
    />
  </StyledIcon>
)
