import { StyledIcon } from '../Icon.Styled'

export const HeartIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      d="M16.583 1.99987C15.7282 2.00462 14.8829 2.17919 14.0961 2.51343C13.3093 2.84767 12.5968 3.33492 12 3.94687C11.114 3.03092 9.97468 2.40033 8.72805 2.13589C7.48142 1.87145 6.18423 1.98519 5.00265 2.46255C3.82107 2.93991 2.8089 3.75915 2.0958 4.81532C1.3827 5.87149 1.00114 7.1165 1 8.39087C1 14.2349 10.994 22.4469 11.42 22.7909C11.584 22.924 11.7888 22.9967 12 22.9967C12.2112 22.9967 12.416 22.924 12.58 22.7909C13.006 22.4469 23 14.2349 23 8.39087C22.9944 6.69307 22.3158 5.06679 21.1128 3.8687C19.9098 2.67061 18.2808 1.99854 16.583 1.99987V1.99987Z"
      fill="currentColor"
    />
  </StyledIcon>
)
