import { StyledIcon } from '../Icon.Styled'

export const StretchingIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="10"
    viewBox="0 0 10 10"
    {...props}
  >
    <path
      fill="currentColor"
      d="M7.495 4.167a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5ZM3.292 6.624l.891 2.674.791-.263-.93-2.787-.752.376Z"
    />
    <path
      fill="currentColor"
      d="M6.191 3.875s-.02-.053-.034-.08a.827.827 0 0 0-.27-.307L2.73 1.323l-.471.687 2.365 1.62-2.083 1.04a.83.83 0 0 0-.458.676l-.83 3.73.814.181.622-2.799 2.992-1.496L6.69 7.646l.78-.292-1.278-3.479Z"
    />
  </StyledIcon>
)
