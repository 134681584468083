import { StyledIcon } from '../Icon.Styled'

export const ButtonPause = (props: any) => {
  return (
    <StyledIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7 1H2C1.73478 1 1.48043 1.10536 1.29289 1.29289C1.10536 1.48043 1 1.73478 1 2V22C1 22.2652 1.10536 22.5196 1.29289 22.7071C1.48043 22.8946 1.73478 23 2 23H7C7.26522 23 7.51957 22.8946 7.70711 22.7071C7.89464 22.5196 8 22.2652 8 22V2C8 1.73478 7.89464 1.48043 7.70711 1.29289C7.51957 1.10536 7.26522 1 7 1Z"
        fill="currentColor"
      />
      <path
        d="M22 1H17C16.7348 1 16.4804 1.10536 16.2929 1.29289C16.1054 1.48043 16 1.73478 16 2V22C16 22.2652 16.1054 22.5196 16.2929 22.7071C16.4804 22.8946 16.7348 23 17 23H22C22.2652 23 22.5196 22.8946 22.7071 22.7071C22.8946 22.5196 23 22.2652 23 22V2C23 1.73478 22.8946 1.48043 22.7071 1.29289C22.5196 1.10536 22.2652 1 22 1Z"
        fill="currentColor"
      />
    </StyledIcon>
  )
}
