import { StyledIcon } from '../Icon.Styled'

export const TimesIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1.56722L1.56722 12L0 10.4328L10.4328 0L12 1.56722Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.56722 0L12 10.4328L10.4328 12L0 1.56722L1.56722 0Z"
      fill="currentColor"
    />
  </StyledIcon>
)
