import { StyledIcon } from '../Icon.Styled'

export const SwissBallToolIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.316 21.038a7.114 7.114 0 0 1-1.408-.276c-1.901-.564-3.89-1.877-5.603-3.695-1.712-1.819-2.947-3.932-3.479-5.951a8.425 8.425 0 0 1-.26-1.496c-.917 3.13-.209 6.682 2.125 9.161 2.334 2.48 5.678 3.231 8.626 2.257Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.699 16.649c1.645 1.748 3.548 3.006 5.358 3.543 1.1.327 2.079.361 2.87.113.68-.402 1.32-.91 1.899-1.524.198-.21.383-.428.558-.653a3.128 3.128 0 0 1-.16.005c-1.949 0-4.89-1.706-7.603-4.588-2.789-2.962-4.408-6.18-4.315-8.246a9.09 9.09 0 0 0-2.05 2.61c-.233.84-.2 1.88.106 3.048.506 1.922 1.691 3.944 3.337 5.692ZM10.702 3.292c-.006.496.17 1.167.52 1.938.627 1.378 1.725 2.937 3.093 4.39 2.384 2.532 4.739 3.852 5.959 3.838a9.667 9.667 0 0 0-.562-4.547.673.673 0 0 1-.285.059c-.719 0-1.85-.876-2.755-1.868-.593-.65-1.082-1.34-1.376-1.938-.263-.536-.351-.956-.264-1.255a8.147 8.147 0 0 0-4.33-.617Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.856 17.46a9.38 9.38 0 0 0 1.33-3.412c-.546-.014-1.216-.217-1.979-.609-1.352-.694-2.874-1.901-4.285-3.4-1.411-1.499-2.548-3.116-3.201-4.552-.37-.811-.56-1.522-.573-2.103-1.13.228-2.226.7-3.212 1.413-.5 1.716 1.179 5.25 4.078 8.33 2.9 3.08 6.227 4.862 7.842 4.332ZM18.711 8.103c.505.305.733.289.775.269a9.116 9.116 0 0 0-1.66-2.48 8.606 8.606 0 0 0-2.269-1.73c.002.118.046.353.24.743.27.547.724 1.18 1.275 1.786.552.605 1.134 1.106 1.64 1.412Z"
      clipRule="evenodd"
    />
  </StyledIcon>
)
