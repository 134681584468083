import { StyledIcon } from '../Icon.Styled'

export const WeightIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.506 4.042c-.646.108-1.099.35-1.606.858-.519.519-.749.956-.859 1.633C2.018 6.676 2 7.672 2 8.89V11H0v2h2v2.11c0 1.218.018 2.214.041 2.357.061.376.181.725.348 1.013.195.337.794.936 1.131 1.13.871.507 2.089.507 2.96 0 .337-.194.936-.793 1.131-1.13.167-.288.287-.637.348-1.013.023-.143.041-1.14.041-2.357V13h8v2.11c0 1.218.018 2.214.041 2.357.061.376.181.725.348 1.013.195.337.794.936 1.131 1.13.871.507 2.089.507 2.96 0 .337-.194.936-.793 1.131-1.13.167-.288.287-.637.348-1.013.023-.143.041-1.14.041-2.357V13h2v-2h-2V8.89c0-1.218-.018-2.214-.041-2.357a2.991 2.991 0 0 0-.348-1.013c-.195-.337-.794-.936-1.131-1.131-.871-.506-2.089-.506-2.96 0-.337.195-.936.794-1.131 1.13a2.991 2.991 0 0 0-.348 1.014C16.018 6.676 16 7.672 16 8.89V11H8V8.89c0-1.218-.018-2.214-.041-2.357-.11-.677-.34-1.114-.859-1.633a3.78 3.78 0 0 0-.62-.511c-.556-.323-1.32-.457-1.974-.347ZM5.5 6.138c.21.123.402.366.461.585.026.098.037 1.94.03 5.367l-.011 5.216-.121.197a.998.998 0 0 1-1.718 0l-.121-.197V6.7l.111-.19a.954.954 0 0 1 .937-.495c.152.004.3.046.432.123Zm14 0c.21.123.402.366.461.585.026.098.037 1.94.03 5.367l-.011 5.216-.121.197a.998.998 0 0 1-1.718 0l-.121-.197V6.7l.111-.19a.954.954 0 0 1 .937-.495c.152.004.3.046.432.123Z"
      clipRule="evenodd"
    />
  </StyledIcon>
)
