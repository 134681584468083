import { StyledIcon } from '../Icon.Styled'

export const MeetingIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
    {...props}
    fill="none"
  >
    <g clipPath="url(#clip0_12202_87812)">
      <path
        d="M46 0H22C20.896 0 20 0.896 20 2V24L30 16H46C47.104 16 48 15.104 48 14V2C48 0.896 47.104 0 46 0Z"
        fill="#95EB00"
      />
      <path
        d="M19.768 38.998C18.046 37.63 15.126 36 11 36C6.874 36 3.954 37.63 2.234 38.996C0.814 40.122 0 41.824 0 43.672V48H22V43.672C22 41.824 21.186 40.122 19.768 38.998Z"
        fill="#95EB00"
      />
      <path
        d="M11 34C14.866 34 18 30.866 18 27C18 23.134 14.866 20 11 20C7.13401 20 4 23.134 4 27C4 30.866 7.13401 34 11 34Z"
        fill="#95EB00"
      />
      <path
        d="M45.768 38.998C44.046 37.63 41.126 36 37 36C32.874 36 29.954 37.63 28.234 38.996C26.814 40.122 26 41.824 26 43.672V48H48V43.672C48 41.824 47.186 40.122 45.768 38.998Z"
        fill="#95EB00"
      />
      <path
        d="M37 34C40.866 34 44 30.866 44 27C44 23.134 40.866 20 37 20C33.134 20 30 23.134 30 27C30 30.866 33.134 34 37 34Z"
        fill="#95EB00"
      />
    </g>
    <defs>
      <clipPath id="clip0_12202_87812">
        <rect width="48" height="48" fill="white" />
      </clipPath>
    </defs>
  </StyledIcon>
)
