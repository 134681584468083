import { StyledIcon } from '../Icon.Styled'

export const ClockIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#clip0_213_277)">
      <path
        d="M12.382 0C5.766 0 .297 5.382.192 11.997a11.783 11.783 0 0 0 11.807 11.997c6.616 0 12.085-5.381 12.19-11.997A11.783 11.783 0 0 0 12.382 0Zm6.792 12.997h-8l.128-7.998h2l-.096 5.999h6l-.032 1.999Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_213_277">
        <path
          fill="currentColor"
          transform="matrix(1 0 -.01597 .99987 .383 0)"
          d="M0 0h23.997v23.997H0z"
        />
      </clipPath>
    </defs>
  </StyledIcon>
)
