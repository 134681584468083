import { StyledIcon } from '../Icon.Styled'

export const ArrowDownIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    {...props}
  >
    <path
      d="M12.7069 22.707 20.4139 15l-1.414-1.414-6 6V2c0-.553-.448-1-1-1s-1 .447-1 1v17.586l-5.99996-6L3.58594 15l7.70696 7.707c.391.391 1.023.391 1.414 0Z"
      fill="currentColor"
    />
  </StyledIcon>
)
