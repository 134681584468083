import { StyledIcon } from '../Icon.Styled'

export const NoEffortIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M.4 23.617a1.282 1.282 0 0 1-.4-.926c0-.347.144-.68.4-.925l21.244-20.36a1.37 1.37 0 0 1 .444-.297 1.417 1.417 0 0 1 1.512.275c.129.123.23.27.3.432a1.262 1.262 0 0 1-.014 1.017c-.073.16-.178.304-.31.425L2.332 23.617a1.397 1.397 0 0 1-.966.383c-.362 0-.71-.138-.966-.383Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 21.332a9.332 9.332 0 1 0 0-18.665 9.332 9.332 0 0 0 0 18.665ZM12 24A12 12 0 1 0 12-.002 12 12 0 0 0 12 24Z"
      fill="currentColor"
    />
  </StyledIcon>
)
