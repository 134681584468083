import { StyledIcon } from '../Icon.Styled'

export const PullUpBarToolIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M21.86.129A2.029 2.029 0 0 0 21.165 0c-.773 0-1.342.501-1.477 1.256a1.526 1.526 0 0 1 .95-.316c.212 0 .43.04.645.118.927.337 1.627 1.32 1.627 2.285 0 .186-.027.368-.08.54a1.55 1.55 0 0 1-.296.541c.328-.05.62-.197.84-.431.275-.293.42-.694.42-1.16 0-1.12-.85-2.309-1.936-2.704Z"
    />
    <path
      fill="currentColor"
      d="M20.638 1.523c.145 0 .294.028.445.083.697.254 1.243 1.017 1.243 1.736 0 .131-.019.254-.054.367a.968.968 0 0 1-.216.378l-.252.293-.979 1.14-.004.005c.002.043.006.086.006.13 0 .12-.012.236-.032.348a1.547 1.547 0 0 1-.224.573l-.003.006c-.016.023-.03.046-.047.068l-.085.101-.635.761-.112.135c-.102.108-.22.198-.349.268a2.048 2.048 0 0 0 .068-.748c-.087-1.16-.974-2.342-2.094-2.75a2.344 2.344 0 0 0-.418-.11c-.137-.029-.467.062-.615.11l1.079-1.14.11-.087a1.39 1.39 0 0 1 .609-.236 1.634 1.634 0 0 1 .458 0h.001l1.154-.948.323-.265a.946.946 0 0 1 .615-.217h.008Z"
    />
    <path
      fill="currentColor"
      d="M17.115 4.967c.947.345 1.718 1.415 1.718 2.386 0 .289-.071.538-.202.72l-.017.024-.011.015-2.755 3.21a2.682 2.682 0 0 1-.02.473l-.117.473c-.06.164-.139.316-.236.453l-.19.227-.537.644-.2.24-.522.626-.214.257-.507.608-.221.265a1.918 1.918 0 0 1-.35.275 2.62 2.62 0 0 0 .23-1.115l-.001-.083c-.041-1.65-1.297-3.376-2.894-3.958a3.096 3.096 0 0 0-.733-.172 2.283 2.283 0 0 0-1.145.155 1.98 1.98 0 0 1 .379-.428l.014-.01c.045-.038.093-.072.141-.105l.68-.54c.055-.05.114-.096.176-.14l.715-.567.156-.124.71-.564a1.7 1.7 0 0 1 .452-.298 1.895 1.895 0 0 1 .814-.172c.091 0 .183.007.276.018l.013.002 3.102-2.556.034-.034.024-.025a.821.821 0 0 1 .164-.136h.002c.277-.175.66-.194 1.072-.044ZM9.394 18.814l2.28-2.649h-.002l-1.544 1.796-.734.853Z"
    />
    <path
      fill="currentColor"
      d="M9.836 11.347c-.615-.223-1.189-.188-1.598.092h-.001a1.212 1.212 0 0 0-.097.073l-.315.261v.006l-1.188.979-.8.662-.71.588c1.993.836 3.628 2.736 4.267 4.807l.734-.854-.236-.25.236.25 1.544-1.795h.002l.199-.231c.033-.035.064-.072.093-.111l.002-.003c.206-.276.315-.644.315-1.074 0-1.383-1.098-2.909-2.447-3.4Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m8.776 19.534-.324.376.324-.376c.083.375.128.75.128 1.121 0 .982-.3 1.821-.868 2.426-.555.593-1.347.919-2.228.919-.48 0-.982-.094-1.49-.279C1.939 22.854 0 20.152 0 17.698c0-2 1.244-3.345 3.096-3.345.166 0 .335.013.505.035l-.09.074.09-.074a4.336 4.336 0 0 1 .826.19l-.363.299-.071.058-.137-.109-.128-.101.128.101h-.005.005l.137.11.008.006.78.62a4.713 4.713 0 0 0-.462-.198 4.002 4.002 0 0 0-.634-.175 3.207 3.207 0 0 0-.589-.056C1.69 15.133.78 16.14.78 17.698c0 2.154 1.707 4.527 3.806 5.29.422.155.834.233 1.222.233.674 0 1.249-.233 1.661-.673.43-.458.657-1.112.657-1.893 0-.115-.006-.23-.016-.346-.092-1.129-.627-2.29-1.42-3.228l1.047.832.698.556c.146.35.262.706.342 1.065Zm-4.963-4.716-.01-.002.01.002Zm.639 8.537c2.243.817 4.062-.392 4.062-2.7 0 2.308-1.819 3.517-4.062 2.7Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M4.78 15.563c.717.35 1.372.883 1.91 1.519l1.045.832.698.556c-.714-1.704-2.17-3.226-3.848-3.837-.05-.018-.1-.034-.15-.05l-.008-.004-.363.299-.071.058.008.007.78.62ZM3.027 18.658c0 .418.171.856.444 1.223.072.096.152.188.237.272.217.216.47.389.744.488.748.273 1.361-.1 1.418-.833a1.377 1.377 0 0 1-.497-.087c-.787-.287-1.425-1.175-1.425-1.984 0-.039.003-.076.006-.113-.54.011-.927.405-.927 1.034Z"
    />
  </StyledIcon>
)
