import { StyledIcon } from '../Icon.Styled'

export const MenuOpenIcon = (props: any) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M2.66667 0H13.3333C14.8 0 16 1.2 16 2.66667V13.3333C16 14.8 14.8 16 13.3333 16H2.66667C1.2 16 0 14.8 0 13.3333V2.66667C0 1.2 1.2 0 2.66667 0ZM13.3333 14.6667c.7334 0 1.3334-.6 1.3334-1.3334V2.66667c0-.73334-.6-1.33334-1.3334-1.33334H2.66667c-.73334 0-1.33334.6-1.33334 1.33334V13.3333c0 .7334.6 1.3334 1.33334 1.3334H13.3333Z"
      fill="currentColor"
    />
    <path
      d="M10.6666 0c.4 0 .6667.26667.6667.66667V15.3333c0 .4-.2667.6667-.6667.6667-.4 0-.66662-.2667-.66662-.6667V.66667c0-.4.26662-.66667.66662-.66667ZM4.66636 8.66665c-.2 0-.33333-.06667-.46667-.2-.26666-.26667-.26666-.66667 0-.93334l2-2c.26667-.26666.66667-.26666.93334 0 .26666.26667.26666.66667 0 .93334l-2 2c-.13334.13333-.26667.2-.46667.2Z"
      fill="currentColor"
    />
    <path
      d="M6.66636 10.6666c-.2 0-.33333-.0666-.46667-.2l-2-1.99995c-.26666-.26667-.26666-.66667 0-.93334.26667-.26666.66667-.26666.93334 0l2 2c.26666.26667.26666.66669 0 .93329-.13334.1334-.26667.2-.46667.2Z"
      fill="currentColor"
    />
  </StyledIcon>
)
